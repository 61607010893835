import Public from '../components/Public/Public'
import Panel from '../components/Panel/Panel'
import Router from "vue-router";
import Vue from 'vue'

const that = Vue.prototype

const disabledRoutes = [
  'OTC',
  'Exchange',
]
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  
 routes :[
  {
    path: "/",
    name: "Public",
    component: Public,
    children:[
      {
        path: "FAQ",
        name: "FAQ",
        component: Public,
      },{
        path: "/",
        name: "home",
        component: Public,
      },{
        path: "Terms",
        name: "Terms",
        component: Public,
        
      },{
        path: "AboutUs",
        name: "AboutUs",
        component: Public,
        
      },{
        path: "ContactUs",
        name: "ContactUs",
        component: Public,
        
      },{
        path: "Markets",
        name: "Markets",
        component: Public,
        
      },
     

    ]
  },
  
  {
    path: "/Panel",
    name: "Panel",
    component: Panel,
    children:[
      {
        path: "",
        name: "Dashboard",
        component: Public,
      }, {
        path: "Wallet",
        name: "Wallet",
        component: Public,
      },{
        path: "Profile",
        name: "Profile",
        component: Public,
      },{
        path: "InviteFriends",
        name: "InviteFriends",
        component: Public,
      },{
        path: "Settings",
        name: "Settings",
        component: Public,
      },{
        path: "Notifications",
        name: "Notifications",
        component: Public,
      },{
        path: "OTC",
        name: "OTC",
        component: Public,
      },{
        path: "Ticket",
        name: "Ticket",
        component: Public,
      },{
        path: "Level",
        name: "Level",
        component: Public,
      },
      { 
        path: "P2P/TETHER/TETHER",
        redirect:'P2P/TOMAN/TETHER'
      }, 
      { 
        path: "P2P",
        redirect:'P2P/TOMAN/BITCOIN'
      },
      {
        path: "P2P/:tradeTo/:tradeFrom",
        name: "P2P",
        component: Public,
      },{
        path: "History",
        name: "History",
        component: Public,
      },
       
    ]
  },
]
})

const forLogin = [
  'History',
  'Dashboard',
  'Panel',
  'P2P',
  'Exchange',
  'TransactionsCrypto',
  'TransactionsRial',
  'Trades',
  'Orders',
  'Wallet',
  'Settings',
  'ActiveOrders',
  'fino-verifying',
  'Notifications',
  'Orders',
  'Trade',
  'Transactions',

]
const unAuthorUser = [
  'P2P',
  'Exchange',
  'Transactions',
  'Trades',
  'Orders',
  'ActiveOrders',
  'Dashboard',
  'Wallet',
  
]
const otp = [
  'TwoStep',
]
const auth = [
  // 'Login',
  // 'SignUp',
  // 'ForgetPassword',
]
router.beforeEach(async (to, from, next) => {

  that.state.loading = true

  if(!that.state.coins){
    await that.$getCoins()
  }

  if (that.state.token) {

      if (!that.state.gotten) {
        that.state.loading = true
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true
      if(that.state.userInfo.supervisor && disabledRoutes.includes(to.name)){
        that.$error('','با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است')
          next(from)
      }
       if (from.name === 'Blog') {
          await window.open(to.fullPath,'_self')
      }
      else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'home'})
      } 
      else if (to.name === 'P2P') {
        // if(!to.params.crypto || !to.params.currency){
        //   next('/Panel/P2P/TOMAN/BITCOIN')
        // }
        // else if(to.params.crypto=='TETHER' && to.params.currency=='TETHER' ){
        //   next('/Panel/P2P/TOMAN/TETHER')

        // }
        if(!that.state.userInfo.authorized){
           next({name: 'HomePage'})
        }
        
        else{
          try {
            that.state.loading = true
            next()
            // next(`/Panel/P2P/${to.params.currency}/${to.params.crypto}`)
        } catch (e) {
            if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                if (otp.includes(from.name)) {
                    next({name: 'HomePage'})
                } else {
                    await that.$error('بازار غیرفعال است')
                    next(from)
                }
            }
        }
        }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'Account') {
                  next({name: 'Account'})
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/Panel/Profile')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } 
  else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
          next({name: 'home'})
      else {
          next()
      }
  }
  that.state.loading = false
})

export default router;