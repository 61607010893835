<template>
<div class="carousel">
    <vueper-slides
    :breakpoints="breakpoints"
    class="no-shadow"
    :visible-slides="4"
    :arrows="false"
    :bullets="false"
    :rtl="true"
    :gap="3"
    :autoplay="true"
    :duration="2000"
      :slide-ratio="1 / 6"
      fixedHeight='351px'
    >
    <vueper-slide  v-for="(crypto,index) in blogs" :key="index" :title="index.toString()">
      <template #content>
      <div class="vueperslide__content-wrapper" >
      
       <div class="post">
     
      <div class="post-img">
          <img  :src='crypto.image'/>
      </div>
      <div class="post-content">
          <div class="post-header">
               <div class="post-title">{{crypto.title}}</div>
               <div class="post-score"><inline-svg :src="require('../../../assets/icons/star.svg')"/>20 </div>
          </div>
          <div class="post-preview">
              {{crypto.text}}

               </div>
<br>
          <!-- <div class="post-footer"> 
            <div class="post-date"><img src='../../../assets/icons/clock.svg'/>  1 &nbsp; هفته &nbsp; قبل </div>
            <div class="author"><span class="author-label">نویسنده</span> &nbsp; &nbsp; <span class="author-name">امیر جلالی</span></div>
          </div> -->
      </div>
  </div>
   

    
      </div>
    </template>
    </vueper-slide>
    </vueper-slides>

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import variables from '../../../library/variables.json'
export default {
  name: 'CryptoCarousel',
  props: {
   
  },
  data(){
    return{
          cryptos:variables.cryptos,
          breakpoints: {
                 
                1200: {
                  visibleSlides:3 
                },
                900: {
                  visibleSlides: 2
                },
                600: {
                  visibleSlides: 1,
                 
                },
               
              
                // The order you list breakpoints does not matter, Vueper Slides will sort them for you.
               
              },
              blogs:[
              {
                title:"ارز دیجیتال تتر چیست ؟",
                text:"تتر (Tether) چهارمین ارز دیجیتال بزرگ جهان از نظر ارزش بازار است. شاید نام‌های متعددی در بازار ارز دیجیتال از جمله بیت کوین و اتریوم به گوشتان خورده باشد، اما آیا می‌دانید برخلاف آن‌ها تتر پراستفاده‌ترین ارز دیجیتال دنیا است؟ اما با وجود این استفاده زیاد یک سری مسائل مختلف پیرامون این ارز در بازار باعث نگرانی برخی از اقتصاددانان بزرگ جهان از جمله یکی از مقامات فدرال رزرو (بانک مرکزی آمریکا) شده است. این موضوع عظمت و اهمیت رمز ارز نام برده را نشان می‌دهد!تتر با واحد اختصاری USDT، یک ارز دیجیتال است که قیمت آن همیشه ثابت است. ارزش هر واحد تتر همواره برابر با ۱ دلار ایالات متحده آمریکا است. هم چنین شما می توانید ارز دیجیتال تتر را از بهترین صرافی های ارز دیجیتال ایران خرید نمایید.",
                image:'https://radindev.com/wp-content/uploads/2022/07/COVER.svg'
              },
              {
                title:"خرید و فروش ارز دیجیتال ریپل",
                text:"ارز دیجیتال ریپل Ripple یک شرکت بزرگ واقع در سانفرانسیسکو آمریکا است که برای بانک‌ها و سیستم های مالی، محصولات مالی غیرمتمرکز می‌سازد. هدف ریپل این است که بتواند انتقال پول بین بانکی و بین‌المللی را سریع‌تر، ارزان‌تر و غیرمتمرکز‌تر کند که البته این هدف تا حد زیادی محقق شده است و اکنون بانک‌ها و شرکت‌های مالی بزرگی برای انجام انتقال‌های پولی خود از محصولات ریپل استفاده می‌کنند.ریپل چند محصول گوناگون برای بانک‌ها و موسسات مالی طراحی کرده است که فقط یکی از آن‌ها با ارز دیجیتال XRP کار می‌کند. بنابراین، شما وقتی XRP می‌خرید، روی یکی از محصولات شرکت ریپل سرمایه‌گذاری کرده‌اید نه خود ریپل ، ریپل با نماد اختصاری XRP یک ارز دیجیتال یا شکلی از دارایی دیجیتال است که با ارزش بازار حدود ۳۱.۳۴ میلیارد دلار، در رتبه ۷ بازار قرار داشته و سهم ۲.۱۱ درصدی از کل بازار را در اختیار دارد.",
                image:'https://radindev.com/wp-content/uploads/2022/07/feature.svg'
              },
              {
                title:"خرید بایننس کوین بدون احراز هویت",
                text:"بایننس کوین ارز دیجیتالی است که توسط صرافی بایننس به بازار عرضه شده است. ارز بایننس در بین کاربران با نام اختصاری bnb نیز شناخته می‌شود. بایننس کوین  در ابتدا به‌عنوان توکنی روی بلاک چین اتریوم راه‌اندازی شد. پس از مدتی ارز بایننس به بلاک چین اختصاصی صرافی بایننس منتقل شد.ارز بایننس کوین یا bnb در ابتدا برای پرداخت کارمزد در صرافی بایننس مورد استفاده قرار می‌گرفت. در حقیقت کاربرانی که از این ارز استفاده  می‌کردند، می‌توانستند کارمزد کمتری را در معاملات بایننس کوین پرداخت کنند.در حال حاضر بایننس کوین به‌ عنوان یکی از باارزش‌ترین و رایج‌ترین کوین‌ها در بین کاربران شناخته‌شده است. بخشی از اعتبار بایننس کوین متعلق به صرافی عرضه‌کننده آن است و صرافی بایننس معتبرترین صرافی کریپتوکارنسی در سراسر دنیا است. بر اساس آمارها روزانه بیش از 150 کوین در این صرافی معامله می‌شود. به‌طور میانگین تقریباً 1.5 میلیون رمزارز در بایننس جا به‌ جا می‌شود. در ادامه نحوه خرید ارز دیجیتال ریپل بدون احراز هویت را برای شما شرح خواهیم داد .",
                image:'https://radindev.com/wp-content/uploads/2022/06/p2p.svg'
              },
              {
                title:"خرید کاردانو از صرافی ایرانی",
                text:"اردانو یکی از محبوب‌ترین ارزهای دیجیتال است و در جدول ارزشمندترین رمزارزهای جهان، در جایگاه نهم ایستاده است. کاردانو ADA در سال 2017 راه اندازی شده و از سال 2019 تا به امروز، یکی از باثبات‌ترین ارزهای دیجیتالی بوده است. چارلز هاسکینسون در سال ۲۰۱۷ کاردانو را راه اندازی کرد. ADA از یک مدل اجماع اثبات سهام (PoS) سازگار با محیط زیست برای ایمن‌سازی بلاک چین خود استفاده می‌کند و برخی گمان می‌کنند که این پروژه یک روش پرداخت جایگزین برای بیت کوین خواهد بود. با این حال، ADA بیشتر با اتریوم رقیب است زیرا به دنبال ایجاد یک اکوسیستم مالی غیرمتمرکز مقیاس‌پذیرتر می‌باشد. از نظر سرعت، کاردانو توانایی انجام ۲۵۷ تراکنش در ثانیه را دارد که بسیار بیشتر از بیت کوین و اتریوم است. اگر که شما قصد خرید این ارز دیجیتال را دارید می توانید با مطالعه ادامه این مقاله اطلاعاتی در ارتباط با نحوه ی خرید ارز دیجیتال کاردانو بدست آورید با ما همراه باشد. ",
                image:'https://radindev.com/wp-content/uploads/2022/05/coinbase-wallet950-450.svg'
              },
              {
                title:"خرید و فروش مستقیم لایت کوین",
                text:"لایت کوین Litecoin یکی از بهترین ارزهای دیجیتال در  جهان است که  شما می توانید با استفاده از این ارز، بدون نیاز به هیچ واسط دیگری پول خود را از شخصی به شخص دیگر انتقال دهید لایت کوین از جمله قدیمی ترین ارز های دیجیتال است که بعد از بیت کوین راهی بازار های ارز دیجیتال شد برای خرید لایت کوین دو روش وجود دارد که روش اول خرید مستقیم ارز دیجیتال لایت کوین می باشد که در این روش به صورت مستقیم با پرداخت ریال و یا دلار می توانید آن را خریداری کنید و روش دیگر از تبدیل کردن یک ارز دیجیتال دیگر به لایت کوین می توان آن را خریداری کرد .  ",
                image:'https://radindev.com/wp-content/uploads/2022/05/smart-contract-technology.svg'
              }
              ]

    }
  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vueperslide__content-wrapper{
   background: var(--crypto-carousel-bg);
   border-radius: 20px;
}
.post{

    height: 100%;
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;
   align-content: flex-start;
}

.carousel{
//   height: 351px;
  width: 100%;margin-top:80px;
  border-radius: 20px;
  overflow: hidden;
}
.vueperslide__title{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.post-img{
    width: 100%;
}

.post-img > img{
    width: 100%;
    height: auto;
}
.post-preview{
    font-size: clamp(12px,1.3vw,13px);
    margin-top: 8px;
    color:var(--publicpage-post-preview);
    text-align: right;
    opacity: 0.7;
}

.post-footer{
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author{
    display: flex;
    font-size: 12px;
}

.author-name{
    font-weight: bold;
    color:var(--publicpage-post-author-name)
}
.author-label{
    color:var(--publicpage-post-author-label)
}

.post-date{
    display: flex;
    align-items: center;
    column-gap: 5px;
    color:var(--publicpage-post-date);
    font-size:12px;
}

.post-title{
    font-size: clamp(14px,1.6vw,16px);
    color:var(--publicpage-post-title);
    font-weight: bold;
}
.post-content{
    padding:16px 14px;
    width: 100%;
}
.post-score{
    display: flex;
    align-items: center;
    color: #FFBF00;
    stroke: #FFBF00;
}

.post-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>