<template>
<div class="trade-comp">
    <SwitchComponent :defaultValue="orderType" @choose="(choice)=>{orderType=choice}" :items="marketOpts" />
    <DropDown class="trade-type" @choose="value=>isBuy=(value=='BUY')" :items="[{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'},]" />
    <div class="price-basecoin">
        <div class="inline-input">
            <DropDown :defaultValue="tradeFrom" class="coin-type" @choose="coin=>$router.push(`/Panel/P2P/${tradeTo}/${coin}`)" :items="coinsOpts" />
        </div>
        <DropDown :key='tradeTo+tradeFrom' :defaultValue="tradeTo" class="base-coin" @choose="baseCoin=>$router.push(`/Panel/P2P/${baseCoin}/${tradeFrom}`)" :items="baseCoinList" />
    </div>
    <!-- قیمت واحد -->
    <LabelInput v-if="orderType!='STOP_MARKET'" type='number' @input="value=>unitPrice=value" :label="`قیمت واحد (${$coinLabel[tradeTo]})`" :value="$toLocal(unitPrice,0)" :disabled="orderType=='MARKET'" />
    <div class="subtext" v-if=" orderType=='LIMIT' || orderType=='MARKET'">
        <div v-if="isBuy" class="subtext-buy"> پایین ترین پیشنهاد فروش{{$toLocal(shopInfo.minSell,$decimal[tradeTo])}} {{$coinLabel[tradeTo]}}</div>
        <div v-else class="subtext-sell"> بالاترین پیشنهاد خرید {{$toLocal(shopInfo.maxBuy,$decimal[tradeTo])}} {{$coinLabel[tradeTo]}}</div>
    </div>

    <!-- حد توقف -->
    <LabelInput v-if="orderType=='STOP_MARKET'" label="حد توقف " />

    <LabelInput v-if="orderType=='STOP_MARKET'" label="بهترین قیمت " />

    <!-- مقدار کوین -->
    <LabelInput v-if="!(orderType=='STOP_MARKET'&& isBuy)" :value="$toLocal(amount,$decimal[tradeFrom])" @input="value=>amount=value" :label="'مقدار ('+$coinUnit[tradeFrom]+')'" type="number" />
    <!-- موجودی     -->
    <div :class="{subtext:true,' subtext-sell':!isBuy,' subtext-buy':isBuy}"> موجودی: {{credit}} {{(isBuy)?$coinLabel[tradeTo]:$coinUnit[tradeFrom]}}</div>
    <!-- مقدار خرید -->
    <LabelInput v-if="orderType=='STOP_MARKET' && isBuy" label="مقدار خرید" />
    <!-- اسلایدر قیمت -->
    <div class="slider-input">
        <input v-model="amountPercent" class="range-input" type='range' />
        <div @click="amountPercent=(i-1)*25" v-for="i in 5" :key="i">
            {{(i-1)*25}}%
        </div>
        <div class="thumb-container">
            <div :style="{left:amountPercent+'%'}" class="thumb">{{amountPercent}}%</div>
        </div>
    </div>

    <LabelInput v-if="orderType=='STOP_LIMIT'" :value="0" @change="value=>stopPrice=value" :label="`حد توقف (${$coinLabel[tradeTo]})`" type="number" />
    <LabelInput v-if="orderType=='STOP_LIMIT'" label="قیمت کل" :disabled="true" />
    <Calendar v-if="orderType=='LIMIT' || orderType=='STOP_LIMIT'" label="تاریخ انقضا" />
    <p class="error">{{errorTxt}}</p>
    <div class="sum">
        <div class="sum-row">
            <span class="cost-sum-label">مبلغ کل سفارش</span>
            <span class="value">{{sum}}</span>
        </div>
        <div class="sum-row">
            <span>کارمزد انتقال</span>
            <span>{{fee}}</span>
        </div>
    </div>
    <button v-if="isBuy" class="buy-btn" :class="{disabled:disabled}" @click="showModal">ثبت سفارش خرید</button>
    <button v-else class="sell-btn" :class="{disabled:disabled}" @click="showModal">ثبت سفارش فروش</button>
    <TradeConfirm @confirm="accept()" v-if="modalShow" @modalToggle="value=>modalShow=value" :order="order" />

</div>
</template>

<script>
import SwitchComponent from '../../tools/SwitchComponent'
import DropDown from '../../tools/DropDown'
import Calendar from '../../tools/Calendar'
import TradeConfirm from '../../Modals/TradeConfirm'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'TradeComp',
    props: ['cryptos', 'shopInfo','minAmount','maxAmount'],
    computed: {
        tradeTo,
        tradeFrom,
        baseCoinList(){
            return [{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}].filter(item=>
            item.value=='TOMAN' || (item.value=='TETHER' && this.tradeFrom!='TETHER'))
        },
        sum() {
            return this.$toLocal(this.$S2N(this.amount) * this.$S2N(this.unitPrice), this.$decimal[this.tradeTo])
        },

        credit() {
            if (this.isBuy)
                return this.wallets[this.tradeTo] ? this.$toLocal(this.wallets[this.tradeTo].credit, this.$decimal[this.tradeTo]) : 0
            else
                return this.wallets[this.tradeFrom] ? this.$toLocal(this.wallets[this.tradeFrom].credit, this.$decimal[this.tradeFrom]) : 0
        },

        fee() {
            return this.$S2N(this.state.userInfo.fee)
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.unitPrice ||
                (this.orderType == 'STOP_LIMIT' && !this.stopPrice) ||
                ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.maxAmount)) ||
                (this.$S2N(this.amount) < this.$S2N(this.minAmount))
        },
        errorTxt() {
            if ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.minAmount)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.tradeFrom].code + ' ' + this.minAmount + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.maxAmount)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.tradeFrom].code + ' ' + this.maxAmount + ' باشد '
            else if (!this.percentSliderActive && this.amountPercent > 0)
                return ' برای استفاده از اسلایدر مقدار ابتدا قیمت را وارد کنید'
            else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.unitPrice && this.orderType.includes('LIMIT'))
                return 'قیمت واحد را وارد کنید'
            else if (this.orderType == 'STOP_LIMIT' && !this.stopPrice)
                return 'قیمت حد توقف را وارد کنید'
            else
                return ''

        },
        percentSliderActive() {
            return !(this.orderType == 'LIMIT' && this.isBuy && !this.unitPrice)
        },

        coinsOpts() {
            return this.cryptos.map(coin => {
                return {
                    label: this.$coinLabel[coin.relatedCoin] + `( ${coin.relatedCoin})`,
                    value: this.$coin2Snake[coin.relatedCoin]
                }
            })
        },

    },
    components: {
        SwitchComponent,
        DropDown,
        Calendar,
        TradeConfirm
    },
    watch: {

        'amountPercent': function () {
            if (this.orderType.includes('MARKET') && this.isBuy)
                this.getAmountFromServer()
            else if (this.percentSliderActive) {
                this.localCalculate()
            }
        },
        'amount': function () {
            if (this.orderType.includes('MARKET')) {
                this.getPriceFromServer()
                console.log(this.unitPrice)
            }
        },

    },
    data() {
        return {
            marketOpts: [{
                    label: 'توقف حد ',
                    value: 'STOP_LIMIT'
                },
                {
                    label: 'توقف بازار ',
                    value: 'STOP_MARKET'
                },
                {
                    label: ' بازار',
                    value: 'MARKET'
                },
                {
                    label: 'حد',
                    value: 'LIMIT'
                }
            ],
            amountPercent: 0,
            stopPrice: 0,
            depsoit: 0.0067,
            isBuy: true,
            modalShow:false,
            amount: 0,
            unitPrice: 0,
            coinsDetails: [],
            
            orderType: "STOP_LIMIT",
            networkOpts: [],
            order:[],

            types: [
                "STOP_LIMIT",
                "STOP_MARKET",
                "MARKET",
                "LIMIT",
            ],
            wallets: []

        }
    },
    methods: {
        async getWallet() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
            }
        },
        showModal() {
            if (!this.disabled) {
                this.order = {
                    amount: this.$S2N(this.amount),
                    unitPrice: this.$S2N(this.unitPrice),
                    baseCoin: this.tradeTo,
                    coin: this.tradeFrom,
                    tradeType: this.isBuy ? 'BUY' : 'SELL',
                    orderType: this.orderType
                }
                if (this.date)
                    this.order.date = this.date
                console.log(this.isBuy)
                this.modalShow = true
                // this.modalType = this.isBuy ? 'BUY' : 'SELL'

            }

        },

        setValue(index) {
            this.amountPercent = this.steps[index].value
        },

        inputRange() {
            this.$emit('rangeValue', this.val)
        },
        // async getCoinsDetails() {
        //     let coin
        //     this.state.loading = true
        //     const res = await this.$axios.get('/coins')
        //     this.coinsDetails = res.content
        //         coin = this.coinsDetails.find(crypto => crypto.coin == this.tradeFrom)
        //         this.maxAmount = coin.maxAmount
        //         this.minAmount = coin.minAmount
        // },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            if (amount) {
                const res = await this.$axios.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: `MARKET_${this.isBuy?'BUY':'SELL'}`
                    }
                })
                let price = res.baseDTO.wholePrice;
                this.totalPrice = this.$toLocal(price.toFixed(0));
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount).toFixed(0));
                console.log(this.unitPrice)
                this.value = this.$S2N(this.totalPrice) / this.cash
            }
        },
        localCalculate() {
            let tempAmount = (this.amountPercent / 100) * this.$S2N(this.credit)
            if (this.isBuy)
                this.amount = tempAmount / this.$S2N(this.unitPrice)

            else
                this.amount = tempAmount
        },
        async getAmountFromServer() {
            let e = this.amountPercent / 100 * this.$S2N(this.credit)
            if (e) {
                this.getAmount = true
                const res = await this.$axios('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                }).catch(() => {})
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[this.tradeFrom])
                    // this.calculate()
                }
            }
        },
        calculate() {
            if (this.orderType.includes('LIMIT') && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (this.orderType.includes('MARKET')) {
                this.serverCalculate()
            }
        },
        async serverCalculate() {
            this.calculating = true
            const a = this.amount
            const temp = ++this.count
            await this.$sleep(500)

            if (a) {
                while (this.alive && a && a === this.amount && temp === this.count) {
                    try {
                        await this.getPriceFromServer()
                        this.calculating = false
                        await this.$sleep(5000)
                    } catch (e) {
                        this.calculating = false
                        break
                    }
                }
            } else {
                this.calculating = false
            }

        },

        filterOrders(filter) {
            return this.orders.filter(order => {
                if (order.tradeType == filter)
                    return order
            })
        },

        isLoaded() {
            return this.wallets && this.crypto && this.tableContents && this.shopInfo.minSell
        },
        async accept() {
            this.loading = true;
            let orderDetail;
            let marketType = this.tradeFrom + '_' + this.tradeTo
            if (this.orderType == 'LIMIT') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `LIMITED_${this.isBuy ? 'BUY' : 'SELL'}`,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.sum),
                }
                if (this.date) {
                    orderDetail.expiresAt = this.date || null
                }
            } else if (this.orderType == 'MARKET') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `MARKET_${this.isBuy ? 'BUY' : 'SELL'}`,
                    wholePrice: this.$S2N(this.sum),
                    unitPrice: this.$S2N(this.unitPrice),


                }

            } else if (this.orderType == 'STOP_LIMIT') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `STOP_LIMIT_${this.isBuy ? 'BUY' : 'SELL'}`,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.sum),
                    stopPrice: this.$S2N(this.stopPrice)
                }
                if (this.date) {
                    orderDetail.expiresAt = this.date || null
                }

            }
            // else if(this.orderType=='STOP_MARKET'){

            // }
            try {
                await this.$axios.post(`/orders/${this.isBuy ? 'buy' : 'sell'}`, orderDetail)
                this.loading = false
                this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
            } catch (error) {
                this.loading = false
                if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                    await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                }
            }
            this.modalShow =false
        },
    },
    mounted() {
        this.getWallet()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.disabled {
    opacity: 0.7;
}

.coin-type {
    z-index: 21 !important;
    min-width: 150px !important;

}

.error {
    color: red;
    font-size: clamp(12px, 1.3vw, 14px);
}

.trade-type {
    z-index: 22 !important;
}

.base-coin {
    z-index: 20 !important;
    flex-basis: 150px !important;

}

.trade-comp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    background: var(--P2P-TradeComp-bg);
    border-radius: 20px;
    padding: 24px;
    flex-grow: 3;
    // max-width: 430px;
    flex-basis: 350px;
}

.sum-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: "YekanLight";
    color: var(--P2P-TradeComp-sum);
}

.slider-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    height: 25px;
    font-size: clamp(10px, 1.1vw, 12px);
    color: var(--P2P-TradeComp-slider-input-stop);
    padding: 0px 5px;
    position: relative;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    height: 25px !important;
    min-width: clamp(40px, 5vw, 60px) !important;

}

.thumb-container {
    width: 90%;
    position: absolute;
    height: 100%;
    margin-left: auto;
}

.thumb-container>.thumb {
    // padding: 4px 18px;
    background: var(--P2P-TradeComp-slider-input-thumb-bg);
    color: var(--P2P-TradeComp-slider-input-thumb-value);
    position: relative;
    border-radius: 40px;
    max-width: clamp(40px, 5vw, 60px);
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, 2px);
    margin-left: -15px;

}

input[type="range"] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    padding: 0;
    opacity: 0;
    height: 100%;
    z-index: 1;
}

.cost-sum-label {
    font-size: clamp(13px, 1.4vw, 14px);
}

.price-basecoin {
    display: flex;
    align-items: center;
    row-gap: 16px;
    column-gap: 8px;
    flex-wrap: wrap;
}

.inline-input {
    flex-grow: 2;
}

.subtext {
    font-family: 'YekanLight';
    font-size: clamp(10px, 1.1vw, 12px);
}

.subtext-sell {
    color: var(--P2P-TradeComp-sell-subtext);
}

.subtext-buy {
    color: var(--P2P-TradeComp-buy-subtext);
}

.sell-btn {
    background: #FF2D78;
    box-shadow: -8px -8px 24px rgba(221, 27, 96, 0.4), 8px 8px 24px rgba(221, 27, 96, 0.4), inset -1px -1px 16px 1px rgba(171, 0, 61, 0.5), inset 1px 1px 16px 1px rgba(171, 0, 61, 0.5);
    border-radius: 50px;
    padding: 8px;
    color: white;
}

.buy-btn {
    background: #80B73B;
    box-shadow: -8px -8px 24px rgba(80, 141, 3, 0.05), 8px 8px 24px rgba(80, 141, 3, 0.05), inset -1px -1px 16px 1px rgba(80, 141, 3, 0.5), inset 1px 1px 16px 1px rgba(80, 141, 3, 0.5);
    border-radius: 50px;
    padding: 8px;
    color: white;

}

@media(max-width:670px) {
    .trade-comp {
        max-width: initial;
    }
}
</style>
