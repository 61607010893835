<template>
  <div class="banner">
        <div class="banner-text">
            <!-- <h1 class="banner-title">معامله را به سادگی با ما تجربه کنید</h1>
            <p class="middle-text">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.</p>
            <p class="subtext"> تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است</p> -->
             <h1 class="banner-title">   صرافی ۷۰۰</h1>
            <p class="middle-text">رمزارزهای دیجیتال محبوب را خرید و فروش کنید و در یکجا وضعیت موجودی خود را دنبال کنید.امکان خرید و فروش بیت کوین و سایر ارزهای دیجیتال در محیطی امن و حرفه‌ای</p>
            <p class="subtext">معامله را به سادگی با ما تجربه کنید</p>
            <button v-if="!state.token" @click="$emit('signupModalToggle')" class="signup-btn">ثبت نام</button>
        </div>
        <div class="image-container">
            <img class="coins-system" src="../../../assets/public/home-banner-img.png"/>
            <img class="moving-coin" src='../../../assets/public/moving-coin.svg'/>
            <img class="moving-coin1" src='../../../assets/public/moving-coin.svg'/>
        </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  width: 100%;
}
.image-container{
// background: url('../../../assets/public/home-banner-img-bg.png');
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  
}
.moving-coin{
  position: absolute;
  animation-name: example;
  animation-duration: 14s;
  margin-right: 4%;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  width: 18%;
}

@keyframes example {
  0% {top:-1%}
  35% {top: 26%; transform: scale(0.7);}
  70% { top: 21%; transform: scale(1.32) rotate(-65deg);}
  100% {top:-1%;transform: scale(1);}
}


.moving-coin1{
  position: absolute;
  animation-name: coin1;
  animation-duration: 14s;
  margin-right: -2%;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  width: 18%;
}

@keyframes coin1 {
  0% {top:64%; transform: scale(0.52) rotate(-40deg); }
  35% {top: 54%; transform: scale(0.52) rotate(5deg);}
  70% { top: 61%; transform: scale(0.52) rotate(-90deg);}
  100% {top:64%; transform: scale(0.52) rotate(-40deg);}

}
.banner{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top:100px;
}

.banner-text{
  
  flex-basis:350px;
  flex-grow: 1;
}
.subtext{
  color: #FFBF00;
    font-size: clamp(13px,1.3vw,14px);

}

.banner-title{
    color: var(--banner-title);
    font-weight: bold;
    font-size: clamp(24px,3vw,36px);
}

.middle-text{
    color:var(--banner-middle-text) ;
    font-weight: lighter;
    font-size: clamp(12px,1.2vw,13px);
}

.signup-btn{
  margin-top:24px;
  background: #FFCA2E;
  border-radius: 50px;
  padding: 9px 32px;
  color: #3D3D3D;
}


.coins-bg{
  mix-blend-mode: lighten;
  opacity: 0.6;
  // position: absolute;
  width: 100%;
  

  // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  
}

.coins-system{
  max-width: min(80%,528px);
        
    height: auto;
}
</style>
