<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        خروح از حساب کاربری
    </template>
    <template #body>
        <p class="text">آیا از خروج از سایت اطمینان دارید؟</p>
        <div class="btns">
            <button class="auth-submit-btn cancel" @click="$emit('modalToggle','')">خیر</button>
            <button class="auth-submit-btn" @click="$logout()">بله</button>
        </div>

    </template> 

  </ModalCard>
    
</template>

<script>
import ModalCard from './ModalCard'
export default {
    name: 'ExitMdodal',
    components: {
        ModalCard,
    },
    

}
</script>

<style scoped>
.disabled {
    opacity: 0.6;
}

.btns{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    column-gap: 10px;
}
.btns > button{
    flex-grow: 1;
}
.text {
    font-size: clamp(14px, 1.5vw, 15px);
    color: var(--modal-text)
}
.cancel{
    background: red;
}
</style>