<template>
        

<div v-if="choice" @click="dropOpen=(disabled)?false:!dropOpen" class="drop-container" :id="'drop-container'+choice.value">
  <div :class="{dropdown:true,disabled:disabled}" >
    <div class="field">
      <!-- <input v-model="choice.label" :disabled="true"/> -->
      <!-- <inline-svg v-if="type=='search'" class="icon" :src="require('../../assets/icons/search.svg')"/> -->
      {{choice.label }}
      <inline-svg  v-if="items.length>1" :class="{icon:true,'drop-open':dropOpen}"  :src="require('../../assets/icons/arrowdown.svg')"/>
    </div>
    <div class="items" :style="{height:(dropOpen)?filterItems.length*40+'px':'0px'}">
      <div class="field" v-for="(item,index) in filterItems" :key="index" @click="choose(item)">
          {{item.label}}
      </div>
    </div>
   
  </div>

</div>
</template>

<script>

export default {
  name: 'DropDown',

  props: {
    placeholder: String,
    type:String,
    items:Array,
    defaultValue:String,
    disabled:Boolean
  },
  computed:{
      filterItems(){
          return this.items.filter(item=>{
              if(item.value!=this.choice.value){
                  return item
              }
          })

      },
  },
   created(){
      document.addEventListener('click',(e)=>{
        if(!e.target.closest('#drop-container'+this.choice.value)){
              this.dropOpen=false
        }
     })
  },
   watch:{
    items(){
      if(!this.choice)
        this.choice=this.items.find(item=>item.value==this.defaultValue)||this.items[0]
    }
  },
  data(){
    return{
      dropOpen:false,
      choice:this.items.find(item=>item.value==this.defaultValue)||this.items[0],
      
    }
  },
  
  methods:{
     
     
      choose(item){
          this.choice=item
          this.$emit('choose',item.value)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropdown{
    position:absolute;
    width: 100%;
    padding: 0px 16px;
    border-radius: 20px;
    background: var(--dropdown-bg);
    border: 1px solid var(--dropdown-border);
    color: var(--dropdown-text);

    z-index: 1;
}

.drop-open{
    transform: rotate(180deg);
}


.items{
    height:0px;
    transition: height 0.8s ease-out;

    cursor: pointer;
    max-height: 140px;
    overflow-y:scroll ;
}

.drop-container{
  cursor: pointer;
    position: relative;
    max-height: 40px;
    height: 40px;
    flex-grow: 1;
    flex-basis: 80px;
    
}
.field{
    height:40px;
    font-size:clamp(13px,1.4vw,14px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
}



.disabled{
  background: var(--label-input-disabled);
}
.icon{
    stroke:var(--dropdown-icon);
    cursor: pointer;
    
   
    
}
// input{
//     padding: 0px!important;
//     background: var(--dropdown-bg);
//     height:100%;
//     width: 100%;
//     color: var(--dropdown-text);
//     font-size: clamp(13px,1.4vw,14px);
// }
</style>
