<template>
<div class="wallet">
    <div class="upper-section">
        <Depsoits :wallets="wallets" :totalPriceOfCredits="totalPriceOfCredits" @changeMarket="value=>marketType=value" @changeCoin="value=>coin=value" />
        <div class="side-section">
            <div v-if="!coin" dir="ltr" class="friends-section">
                <div dir="rtl" class="friends-count-sec">
                    <div class="friends-count">
                        <div>{{inviteData.countInvitees}}</div>
                        <inline-svg :src="require('../../../assets/icons/InviteFriends.svg')"/>
                    </div>
                    <div class="friends-label">تعداد دوستان من</div>
                </div>
                <div dir="rtl" class="friends-profit-sec">
                    <div class="friends-label">مجموع سود من</div>
                    <div class="friends-profit">{{$toLocal(inviteData.profit,0)}} تومان</div>
                    <button class="friends-btn" @click="$router.push('InviteFriends')">دعوت دوستان</button>
                </div>

            </div>
            <CardsComp v-if="!coin" />

            <div v-if="!coin" class="total-profit-section">
                <div class="total-profit-title">سود کلی</div>
                <div class="total-profit">
                    <div class="total-profit-percent">{{totalProfitPercent}}%
                        <inline-svg :class="{'negative-arrow':totalProfitPercent<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                    </div>
                    <div total-profit-value>{{totalProfitValue.toLocaleString()}}</div>
                </div>
            </div>
            <ChartSection v-if="coin" :coin="coin" />
            <ChartSection v-if="!coin" />
        </div>

    </div>
    <WalletTable v-if="coin" :coin="coin[0]" />

</div>
</template>

<script>
import Depsoits from './Depsoits'
import CardsComp from '../../tools/CardsComp'
import ChartSection from './ChartSection'
import WalletTable from './WalletTable'
export default {
    name: 'Wallet',
    components: {
        Depsoits,
        CardsComp,
        ChartSection,
        WalletTable
    },
    props: {
        msg: String
    },
    data() {
        return {

            totalProfitValue: 12875,
            totalProfitPercent: 10,
            inviteData: {},
            coin: null,
            marketType: 'P2P',
            wallets: {},
            totalPriceOfCredits: 0,
            loading: true

        }
    },

    watch: {
        marketType() {
            if (this.marketType == 'EXCHANGE')
                this.getExchangeCoins()
            else
                this.getP2PCoins()
        }
    },
    methods: {
        async getP2PCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true
            let cryptos
            let crypto
            const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=P2P`)
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => cryptos = response.content.filter(coin => coin.marketType.includes('TOMAN')))
            for (const [key, value] of Object.entries(this.wallets)) {

                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.marketType.includes(key))
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.change = crypto.last24HourChange
                        value.price = crypto.lastPrice

                        this.totalPriceOfCredits += value.wholePriceOfCredit
                    }
                }
                this.loading = false
                this.state.loading = false
            }

        },

        async getExchangeCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true

            let cryptos
            let crypto
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => cryptos = response)

            for (const [key, value] of Object.entries(this.wallets)) {
                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.destCoinSymbol === key)
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.change = crypto.price24hrChangePercent
                        value.price = crypto.price
                        this.totalPriceOfCredits += value.wholePriceOfCredit

                    }

                }
            }
            this.loading = false
            this.state.loading = false

        },
           async getData() {

            const res = await this.$axios.get('/users/invites');
            this.inviteData = res.baseDTO;

        }
    },
    mounted() {
        this.getP2PCoins()
        this.getData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.wallet {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.upper-section {
    flex-grow: 1;
    display: flex;
    row-gap: 16px;
    column-gap: 16px;
    flex-wrap: wrap;
}

.side-section {
    flex-grow: 1;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 16px;
    justify-content: center;
    row-gap: 16px;
    max-width: 258px;
    flex-basis: 200px;

}

.negative-arrow {
    transform: rotate(180deg);
}

.total-profit-percent {
    color: var(--dashboard-totalProfit-percent);
    stroke: var(--dashboard-totalProfit-percent);
    font-size: clamp(11px, 1.1vw, 12px);
    font-weight: bold;
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.total-profit-value {
    font-size: clamp(16px, 1.7vw, 18px);
    font-weight: bold;

}

.total-profit-section {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    background: var(--dashboard-section-bg);
    color: var(--dashboard-totalProfit-text);
    flex-direction: column;
    row-gap: 8px;
    padding: 16px 24px;
    border-radius: 10px;
}

.total-profit-title {
    font-family: 'YekanLight';
    font-size: clamp(14px, 1.5vw, 16px);
}

.total-profit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

//friends section

.friends-section {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 16px;
    column-gap: 10px;
    background: var(--dashboard-section-bg);
    border-radius: 20px;
    display: flex;
    flex-grow: 1;

}

.friends-label {
    color: var(--dashboard-friends-label);
    font-size: clamp(12px, 1.2vw, 13px);
    font-family: 'YekanLight';
}

.friends-count {
    display: flex;

    justify-content: space-between;
    color: var(--dashboard-friends-count);
    stroke: var(--dashboard-friends-count);
    font-size: clamp(16px, 1.7vw, 18px);
    font-weight: bold;
}

.friends-profit {
    font-size: clamp(14px, 1.5vw, 16px);
    color: var(--dashboard-friends-profit);
    font-weight: bold;
}

.friends-btn {
    color: #3D3D3D;
    background: #FFBF00;
    border-radius: 50px;
    padding: 4px 16px;
    font-size: clamp(13px, 1.4vw, 14px);
    font-family: 'YekanLight';
}

.friends-profit-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.friends-count-sec {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: flex-end;
}

@media(max-width:1245px) {
    .side-section {
        max-width: initial;
        flex-direction: initial;
    }
}
</style>
