<template>
<div>

    <div class="filters">
        <div class="right-filter">
            <SwitchFilter @choose="(choice)=>{marketType=choice}" :items="[{label:'معاملات آسان',value:'EXCHANGE'},{label:'معاملات پیشرفته',value:'MARKET'},]" />
        </div>
        <div class="left-filter">
            <DropFilter class="f-1" v-if="marketType!='EXCHANGE'" @choose="(value)=>{marketType=value.value}" :items="marketTypes" />
            <DropFilter class="f-2" @choose="(value)=>{dealType=value.value}" :items="[{label:'خرید و فروش',value:''},{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'}]" />
            <DropFilter class="f-3" @choose="(value)=>{coin=value.value}" :items="coinsList" />
        </div>
    </div>
    <div class="table-container">
        <perfect-scrollbar @ps-y-reach-end="getTrades()" ref="scroll">
            <table>
                <tr class="table-header">
                    <td class="resp-1"> زمان </td>
                    <td>نوع ارز </td>
                    <td class="resp-3">نوع سفارش</td>
                    <td> مقدار <span class="tag" v-if="coin.value">({{coin}})</span></td>
                    <td class="resp-2" v-if="marketType=='STOP_LIMIT'">حد توقف</td>
                    <td class="resp-3">قیمت واحد</td>
                    <td class="resp-1" v-if="marketType.includes('LIMIT')">تاریخ انقضاء</td>
                    <td class="resp-3">قیمت کل</td>
                    <td class="resp-3">شناسه تراکنش/وضعیت </td>
                </tr>
                <tbody>
                    <tr class="table-row" v-for="(order,index) in trades" :key="index">
                        <!-- تاریخ -->
                        <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                        <!-- نوع ارز -->
                        <td>
                            <div class="coin-title">
                                <img class="coin-icon resp-3" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                                <span>{{$coins[coinName(order.marketType)].persianName}}</span>
                                <span class="tag">({{$coins[coinName(order.marketType)].code}})</span>
                            </div>
                        </td>
                        <!-- نوع سفارش -->
                        <td :class="{'resp-3':true,'depsoit-buy':order.orderType.includes('BUY'),'withdraw-sell':order.orderType.includes('SELL')}">
                            {{dict[order.orderType.includes('BUY')?'BUY':'SELL']}}
                        </td>
                        <!-- مقدار سفارش -->
                        <td>{{order.amount}}</td>
                        <!-- قیمت واحد -->
                        <td class="resp-3">{{$toLocal(order.unitPrice,$decimal[baseCoin(order.marketType)])}}<span class="tag">({{$coins[baseCoin(order.marketType)].persianName}})</span></td>
                        <!-- قیمت کل -->
                        <td class="resp-3">{{$toLocal(order.wholePrice,$decimal[baseCoin(order.marketType)])}}<span class="tag">({{$coins[baseCoin(order.marketType)].persianName}})</span></td>
                        <!-- وضعیت -->
                        <td class="resp-3">
                            <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </perfect-scrollbar>
    </div>
    <!-- 
    <table v-if="marketType=='MARKET' || marketType=='LIMIT'">
        <tr class="table-header">
            <td>ارز</td>
            <td>نوع سفارش</td>
            <td> مقدار</td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">کارمزد</td>
            <td class="resp-2">سود</td>
            <td class="resp-1">زمان</td>
            <td class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in trades" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="coin-icon resp-3" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                        <span>{{$coins[coinName(order.marketType)].persianName}}</span>
                        <span class="tag">({{$coins[coinName(order.coinName)].code}})</span>
                    </div>
                </td>
                <td :class="{'depsoit-buy':order.marketType=='BUY','withdraw-sell':order.marketType=='SELL'}">{{dict[order.marketType]}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.commission}}</td>
                <td class="resp-2">{{order.profit}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-3">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table>

    <table v-if="marketType=='STOP_MARKET'">
        <tr class="table-header">
            <td>نوع ارز</td>
            <td class="resp-1">زمان </td>
            <td class="resp-3">نوع سفارش</td>
            <td class="resp-2">حد توقف<span class="tag">(تومان)</span></td>
            <td class="resp-3">قیمت خرید</td>
            <td>مقدار ارز</td>
            <td class="resp-1"> کارمزد</td>
            <td>مبلغ کل</td>
            <td class="resp-2"> سود</td>
            <td class="resp-3"> وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="coin-icon" :src="require('../../../../public/coins/'+$coinUnit[order.coinName]+'.png')" />
                        <span>{{$coinLabel[order.coinName]}}</span>
                        <span class="tag">({{$coinUnit[order.coinName]}})</span>
                    </div>
                </td>
                <td class="resp-1">{{order.date}}</td>
                <td :class="{'resp-3':true,'depsoit-buy':order.marketType=='BUY','withdraw-sell':order.marketType=='SELL'}">{{dict[order.marketType]}}</td>
                <td class="resp-2">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-3">بهترین قیمت</td>
                <td>{{order.amount}}</td>
                <td class="resp-1">{{order.commission}}</td>
                <td>{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-2">{{order.profit}}</td>
                <td class="resp-3">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table>

    <table v-if="marketType=='STOP_LIMIT'">
        <tr class="table-header">
            <td>نوع ارز</td>
            <td class="resp-1">زمان</td>
            <td class="resp-3">نوع سفارش </td>
            <td class="resp-3">قیمت واحد(تومان)</td>
            <td> مقدار ارز </td>
            <td class="resp-2">حد توقف(تومان)</td>
            <td class="resp-1">کارمزد</td>
            <td class="resp-1">تاریخ انقضاء</td>
            <td class="resp-2">سود</td>
            <td class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in trades()" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="coin-icon" :src="require('../../../../public/coins/'+$coinUnit[order.coinName]+'.png')" />
                        <span>{{$coinLabel[order.coinName]}}</span>
                        <span class="tag">({{$coinUnit[order.coinName]}})</span>
                    </div>
                </td>
                <td class="resp-1">{{order.date}}</td>
                <td :class="{'resp-3':true,'depsoit-buy':order.marketType=='BUY','withdraw-sell':order.marketType=='SELL'}">{{dict[order.marketType]}}</td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-2">{{order.unitPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.commission}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-2">{{order.profit}}</td>
                <td class="resp-3">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table> -->

</div>
</template>

<script>
import SwitchFilter from './SwitchFilter'
import DropFilter from './DropFilter'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Trades',
    components: {
        SwitchFilter,
        DropFilter,
    },
    watch: {
        query() {
            this.page = 0
            this.lastPage = 1
            this.getTrades()
        }
    },

    computed: {
        coinsList() {
            let coins = Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].persianName,
                    value: coin[0]
                }
            })
            coins.unshift({
                label: 'همه ارز ها',
                value: ''
            })
            return coins
        },

        query() {
            let coinsStr = '?size=20'
            coinsStr += this.marketType == 'EXCHANGE' ? '&type=EXCHANGE' : '&type=P2P'

            if (this.coin) {
                coinsStr += '&marketType=' + this.coin + '_' + 'TOMAN'
                coinsStr += '&marletType=' + this.coin + '_' + 'TETHER'
            }
            if (this.dealType) {
                coinsStr += '&orderType=' + this.marketType + '_' + this.dealType
                coinsStr += '&orderType=' + this.dealType
            } else {

                coinsStr += '&orderType=' + this.marketType + '_' + 'BUY'
                coinsStr += '&orderType=' + this.marketType + '_' + 'SELL'
            }

            return coinsStr
        },
    },
    methods: {
        coinName(market) {
            return market.includes('TOMAN') ? market.replace('_TOMAN', '') : market.replace('_TETHER', '')
        },
        baseCoin(market) {
            return market.includes('TOMAN') ? 'TOMAN' : 'TETHER'
        },

        // async getP2POrders() {
        //     // if (this.page <= this.lastPage) {
        //     this.page++
        //     let [res, ] = await this.$http.get('/orders', {
        //         params: {
        //             market: 'BITCOIN' + '_' + 'TOMAN',
        //             orderType: 'P2P',
        //             size: 20
        //         }
        //     })
        //     if (res.content)
        //         this.P2PDataSet = res.content;

        //     this.loading = false
        // },
        // async getExchangeOrders() {
        //     // if (this.page <= this.lastPage) {
        //     this.page++
        //     let [res, ] = await this.$http.get('/orders', {
        //         params: {
        //             // market: this.tradeFrom + '_' + this.tradeTo,
        //             type: 'EXCHANGE',

        //             size: 20
        //         }
        //     })
        //     if (res.content)
        //         this.exchangeDataSet = res.content;
        //     this.loading = false
        // },

    async getTrades() {
            this.loading = true
            if (this.page <= this.lastPage) {
                this.page++
                let [res, ] = await this.$http.get(`/trades${this.query+'&page='+this.page}`)
                this.trades = [...this.trades,...res.content];
                this.lastPage = res.totalPages
            }
            this.loading = false
        }
    },
    mounted() {
        if (this.state.loop) {
            this.loop = new Loop(this.getTrades, 5000, 'getTrades')
            this.loop.start()
        } else {
            this.getTrades()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    },
    data() {
        return {
            coin: '',
              page:0,
            lastPage:1,
            trades: [],
            marketType: 'EXCHANGE',
            is_open: true,
            dealType: '',
            dict: {
                'SELL': 'فروش',
                'BUY': 'خرید'
            },
            coins: [{
                    label: 'همه ارزها',
                    value: ''
                },
                {
                    label: 'بیت کوین',
                    value: 'BTC'
                },
                {
                    label: 'اتریوم کلاسیک',
                    value: 'ETC'
                },
                {
                    label: ' اتریوم',
                    value: 'ETH'
                },
            ],
            marketTypes: [{
                    label: 'بازار',
                    value: 'MARKET'
                },
                {
                    label: 'بازار نوقف',
                    value: 'STOP_MARKET'
                },
                {
                    label: ' حد',
                    value: 'LIMITED'
                },
                {
                    label: 'حد توقف',
                    value: 'STOP_LIMIT'
                },
            ],

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.f-1 {
    z-index: 22 !important;
}

.f-2 {
    z-index: 21 !important;
}

.f-3 {
    z-index: 20 !important;
}

.left-filter {
    flex-grow: 1;
    max-width: 400px;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
