<template>
  <div id="app">
    <CodeInput
      dir="ltr"
      class="input"
      :fields="num"
      :fieldWidth="33"
      :fieldHeight="28"
      :loading="false"
      @change="(e) => $emit('change', e)"
      @complete="(e) => $emit('complete', e)"
    />
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "VerifyCodeInput",
  props: {
    num: Number,
  },
  data() {
    return {
      code: [],
    };
  },
  components: {
    CodeInput,
  },
  methods: {
    setPlaceholder() {
      for (let i = 0; i < this.num; i++) {
        this.$refs["code-input"].$refs["input_" + i][0].placeholder = 0;
      }
    },
    reset() {
      this.otp.code = "";
      for (let i = 0; i < this.num; i++) {
        this.$refs["code-input"].values[i] = "";
        this.$refs["code-input"].$refs["input_" + i][0].value = "";
      }
      this.$refs["code-input"].$refs["input_0"][0].focus();
    },
  },
  mounted() {
    this.setPlaceholder();
  },
};
</script>

<style scoped>
.code-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.code-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.code-input > input {
  border: solid 1px #a8adb7;
  font-family: "Lato";
  font-size: 20px;
  border-radius: 8px;
  text-align: center;
  transition: 0.2s all ease-in-out;
  color: #525461;
  box-sizing: border-box;
  -webkit-appearance: initial;
}
.code-input > input:focus {
  outline: none;
  border: 1px solid #006fff;
  caret-color: #006fff;
}
.title {
  margin: 0;
  height: 20px;
  padding-bottom: 10px;
}
</style>
