<template>
<ModalCard @close="$emit('modalToggle','')">
     <template #title>
        جزئیات درﺧﻮاﺳﺖ 
    </template>
     <template #body>
         <div>
             <p class="text">درخواست شما به شرح زیر است در صورت اطمینان تایید فرمایید</p>
             <div class='detail'>
                 <span>نوع درخواست</span>
                 <span :class="{buy:order.tradeType=='BUY' ,sell:order.tradeType=='SELL'}">{{dict[order.tradeType]+' '+dict[order.orderType]}}</span>
             </div>  
             <div class='detail'>
                 <span> مقدار</span>
                 <span dir="ltr">{{order.amount}}&nbsp;{{$coins[order.coin].code}}</span>
             </div>  
             <div class='detail'>
                 <span>قیمت واحد</span>
                 <span>{{ $toLocal(order.unitPrice,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].persianName}}</span>
             </div>  
              <div class='detail'>
                 <span> مجموع</span>
                 <span>{{$toLocal(sum,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].persianName}}</span>
             </div>    
             <div class='detail' v-if="order.date">
                 <span> تاریخ</span>
                 <span>{{$G2J(order.date)}}</span>
             </div>   
            
         </div>
         <button class="modal-submit-btn" @click="$emit('confirm')" >تایید </button>
     </template> 
   

  </ModalCard>
    
</template>

<script>
import ModalCard from './ModalCard'
export default {
  name: 'AddTicket',
  components:{
      ModalCard,
  },
  props:['order'],
  computed:{
      sum(){
          return this.order.unitPrice * this.order.amount
      }
  },
  data(){
    return{
        dict:{
            "MARKET":'بازار',
            "LIMIT":'حد',
            "EXCHANGE":'صرافی',
            "BUY":'خرید',
            "SELL":'فروش'
        }
      
    }
  }
}
</script>
<style scoped>
.text{
    font-size: clamp(13px,1.4vw,15px);
    color:var(--modal-text)

}
.buy{
    color: var( --modal-orderType-buy);
}
.sell{
    color: #DD1B60;
}
.details{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    color: var(--modal-text)
}
.detail , .detail *{
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    font-size: clamp(12px,1.4vw,14px);
    font-family: 'YekanLight';
}

</style>