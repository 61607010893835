<template>
<div class="section">
    <div v-if="!readOnly" class="section-title">آپلود مدارک</div>
    <p v-if="!readOnly" class="text">لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به
        صورت کاملا خوانا و واضح ،دقیقا مانند تصویر نمونه
        ،ارسال نمایید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید
        هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.
    </p>
    <div class="upload-section">
        <p v-if="!readOnly" class="pattern">
            این جانب ........... با شماره ملی ....... در تاریخ ........... مدارک خود را جهت احراز هویت در سایت بی بیتکس
            ارسال و اعلام می کنم مدارک، حساب
            کاربری و اطلاعات کارت بانکی خود را در اختیار دیگری قرار نداده ام و متعهد می شوم از دریافت وجه و خرید ارز
            دیجیتال برای اشخاص دیگر تحت هر عنوانی )آگهی استخدام، کسب سود، کاریابی در منزل و ....( خودداری کنم.
        </p>
        <div class="upload-inputs">
            <div class="upload-container">
                <input type="file" :disabled="readOnly" @change="upload($event.target,true)" accept="image/*" name="image" id="image" />
                <div class="svg-container">
                    <img v-if="imagePath" :src="imagePath" id='user-img' />
                    <inline-svg v-else-if="!readOnly" class="upload-icon" :src="require('../../../assets/icons/upload.svg')" />
                    <img v-else src="../../../assets/public/userimg.png" />
                </div>
                <button v-if="!readOnly" class="upload-btn">آپلود عکس سلفی</button>
                <button v-else class="upload-btn">عکس سلفی و اطلاعات شخصی</button>
            </div>
            <div class="upload-container">
                <input type="file" :disabled="readOnly" @change="upload($event.target,false)" accept="image/*" name="document" id="document" />
                <div class="svg-container">
                    <img v-if="documentPath" :src="documentPath" id='user-img' />
                    <inline-svg v-else-if="!readOnly" class="upload-icon" :src="require('../../../assets/icons/upload.svg')" />
                    <img v-else src="../../../assets/public/userimg.png" />
                </div>
                <button v-if="!readOnly" class="upload-btn">آپلود عکس مدارک</button>
                <button v-else class="upload-btn">عکس مدارک ثبت نام</button>
            </div>

        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'ImageUpload',
    props: {
        data: Object,
        readOnly: Boolean
    },
    watch: {
        'data.image'() {
            if (this.data.image)
                if (typeof this.data.image === 'string')
                    this.imagePath = this.data.image
        },
        'data.document'() {
            if (this.data.image)
                if (typeof this.data.document === 'string')
                    this.documentPath = this.data.document
        }
    },
    data() {
        return {
            documentPath: '',
            imagePath: '',
            loadedpicture: '',
            hovered: '',
        }
    },
    methods: {
        drop(e) {
            let dt = e.dataTransfer
            this.upload(dt)
        },
        upload(evt, isImage) {
            let file = evt.files[0]
            isImage ? this.data.image = file : this.data.document = file
            let fileReader = new FileReader();
            fileReader.addEventListener("loadstart", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            })
            fileReader.addEventListener("progress", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            });
            fileReader.addEventListener('load', (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                isImage ? this.imagePath = event.target.result : this.documentPath = event.target.result
            });
            fileReader.readAsDataURL(file);
        },
        loadFile(event) {
            var image = document.getElementById('output-img');
            // this.data.image=URL.createObjectURL(event.target.files[0]);
            image.src = URL.createObjectURL(event.target.files[0]);
            document.getElementById('upload-image-sub').style.display = "none";
        }

    }

}
</script>

<style scoped>
.upload-section {
    display: flex;
    column-gap: 32px;
    justify-content: space-between;
    flex-wrap: wrap;
}

input[type="file"] {
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 1;
    height: 100%;
}

.svg-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

#user-img{
    width: 70px;
    height: 70px;
}
.upload-inputs {
    display: flex;
    align-items: center;
    row-gap: 16px;
    flex-wrap: wrap;
    column-gap: 16px;
    flex-grow: 1;
}

.upload-btn {
    background: #FFBF00;
    color: #3D3D3D;
    width: 100%;
    border-radius: 50px;
    height: 28px;
}

.upload-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--authentication-bg);
    border: 1px solid var(--authentication-upload-border);
    flex-grow: 1;
    max-width: 186px;
    padding: 8px;
    height: 116px;
    border-radius: 20px;
}

.upload-icon {
    stroke: #FFBF00;

}

.pattern {
    color: var(--authentication-upload-text-pattern);
    font-size: clamp(12px, 1.3vw, 13px);
    flex-grow: 3;
    flex-basis: 100px;

}

.text {
    color: var(--authentication-upload-guide-text);
    font-size: clamp(12px, 1.3vw, 13px);

}
</style>
