<template>
<div class="settings">
    <div class="two-step-verification section">
        <div class="page-title">تایید هویت دو مرحله‌ای</div>
        <p class="text">برای امنیت بیشتر حساب کاربری، فعالسازی یکی از روش های شناسایی دو عاملی الزامی میباید.</p>
        <div>
            <div class="option" v-for="(option,index) in options" :key="index" @click="option.name==twoStep?'':showModal(option.name)">
                <input @click.prevent=""   name="verifyMethod" type="radio" :checked="option.name==twoStep" />
                <label>{{option.title}}</label>
            </div>

        </div>
    </div>
    <div class="change-pass section">
        <div class="change-pass-label">ویرایش رمز عبور کاربری</div>
        <button @click="modalType='ChangePass'" class="change-pass-btn">تغییر رمز عبور</button>
    </div>

    <ChangePass @modalToggle="(value)=>modalType=value" v-if="modalType=='ChangePass'" />
    <OtpVerify @modalToggle="(value)=>modalType=value"  v-if="options.some(item=>item['name']==modalType)" @submit="submitted($event)" :qrCode="qrCode" :mobile="mobile" :twoStep="modalType" />
   
</div>
</template>

<script>
import ChangePass from '../Modals/ChangePass'
import OtpVerify from '../Modals/OtpVerify.vue'

export default {
    name: 'Settings',
    components: {
        ChangePass,
        OtpVerify,
      

    },
    props: {
        msg: String
    },
    data() {
        return {
            options: [
                {
                    name: 'EMAIL',
                    title: "Email"
                },
                {
                    name: 'GOOGLE_AUTHENTICATOR',
                    title: "Google Authenticator"
                },
                {
                    name: 'SMS',
                    title: "SMS"
                },
            ],
            modalType: '',
            verifyMethod: '',
            twoStep: this.state.towStepAuthenticationType,
            qrCode: '',
            qrLink: '',
            email: this.state.userInfo.email,
            mobile: '',
            completedText: '',
            choosed: '',

        }
    },
    methods: {

        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType
        },
        async showModal(methodType) {
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${methodType}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                 this.modalType = methodType
                console.log(this.twoStep)
                console.log(res)
            }
            this.getTwoStepAuth()
            this.state.loading=false
        },
        async submitted(e) {
            this.completedText = e;
            await this.$error('', e, 'success')
            await this.$getuserInfo()
            this.getTwoStepAuth()

        },
    },
    mounted() {
        this.getTwoStepAuth()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
input[type='radio'] {
    cursor: pointer;
}

.page-title {
    color: var(--settings-page-title);
    font-size: clamp(18px, 2vw, 24px);
    font-weight: bold;
}

.text {
    color: var(--settings-text);
    font-size: clamp(13px, 1.3vw, 14px);
}

.settings {
    flex-grow: 1;
}

.option {
    font-size: clamp(13px, 1.4vw, 14px);
    color: var(--settings-option);
}

.section {
    padding: 16px;
    background: var(--settings-section-bg);
    box-shadow: 0px 0px 64px rgba(28, 57, 187, 0.05);
    border-radius: 20px;
}

.change-pass-label {
    color: var(--settings-changepass-label);
    font-size: clamp(15px, 1.7vw, 18px);
    font-weight: bold;
}

.change-pass-btn {
    background: var(--settings-changepass-btn-bg);
    color: var(--settings-changepass-btn-text);
    border-radius: 50px;
    padding: 8px;
    min-width: 100px;
    flex-grow: 1;
    font-size: clamp(14px, 1.5vw, 16px);
    max-width: 253px;
}

.change-pass {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    column-gap: 24px;
}
</style>
