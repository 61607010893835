<template>
<div class="cards">
    <div class="change-container" @click="changeCard()">
        <inline-svg :src="require('../../assets/icons/change-card.svg')" />
    </div>
    <div v-for="(card,index)   in showedCards" :key="index" :class="{card:true,'gold-card':(activeCard+index)%3==0,'purple-card':(activeCard+index)%3==1,'pink-card':(activeCard+index)%3==2,'left-card':index==1} ">

        <div class="sheba-number">IR {{card.shebaCode}}</div>
        <div class="card-number">{{card.cardNumber}} </div>
        <div class="card-owner">
            <div class="card-owner-label">دارنده کارت</div>
            <div class="card-owner-name">{{state.userInfo.firstName+' '+state.userInfo.lastName}}</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'CardsComp',

    data() {
        return {
            cardsBg: [
                'gold',
                'purple',
                'pink'
            ],
            activeCard: 0,
            cards: this.state.userInfo.bankAccounts,
            // showedCards: []
            //  cards: [
            //     {
            //         owner: 'جان تئودور',
            //         shebaCode: '3467 2967 2598 4532 2598 4532',
            //         cardNumber: '3467 2967 2598 4532'
            //     },
            //     {
            //         owner: 'شایان کریمی',
            //         shebaCode: '3467 2967 2598 4532 2598 4532',
            //         cardNumber: '3467 2967 2598 4532'
            //     },
            //     {
            //         owner: 'مائده صادقی',
            //         shebaCode: '3467 2967 2598 4532 2598 4532',
            //         cardNumber: '3467 2967 2598 4532'
            //     },
            //     {
            //         owner: 'احمد یزدانی',
            //         shebaCode: '3467 2967 2598 4532 2598 4532',
            //         cardNumber: '3467 2967 2598 4532'
            //     },
            //     {
            //         owner: 'سمانه ساداتی',
            //         shebaCode: '3467 2967 2598 4532 2598 4532',
            //         cardNumber: '3467 2967 2598 4532'
            //     },
            // ],
        }
    },
    computed: {
        showedCards(){
            if (this.activeCard == this.cards.length) {
                return [this.cards.slice(0,2)];
            } 
            else if ((this.activeCard + 1) == this.cards.length && this.cards.length>1) {
                return [this.cards[this.activeCard],this.cards[0]];

            } 

            return this.cards.slice(this.activeCard,this.activeCard+2);
            

        }

    },

    methods: {
        changeCard() {
            console.log(this.showedCards)
            this.activeCard++;

            if(this.activeCard >= this.cards.length)
                 this.activeCard = 0;



        },
        // setshowedCards() {
      
        //     if (this.activeCard >= this.cards.length) {
        //         this.activeCard = 0;
        //         this.showedCards[0] = this.cards[0];
        //         this.showedCards[1] = this.cards[1];

        //     } else if (this.activeCard + 1 == this.cards.length) {
        //         this.showedCards[0] = this.cards[this.activeCard]
        //         this.showedCards[1] = this.cards[0]
        //     } else {
        //         this.showedCards[0] = this.cards[this.activeCard]
        //         this.showedCards[1] = this.cards[this.activeCard + 1]
        //         console.log('jkhkhkhh')
        //     }

        // }
    },
    mounted() {
     

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.cards {
    align-items: center;
    display: flex;
    position: relative;
    flex-grow: 1;
    min-width: 248px;
    height: 128px;
    max-height: 128px;
}

.card {
    width: 100%;
    padding: 16px;
    width: 228px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    z-index: 1;
    position: absolute;
    color: #3D3D3D;
    flex-direction: column;
}

.left-card {
    left: 0px;
    z-index: 0;
}

.pink-card {
    background: url('../../assets/public/pink-card.png');
    background-size: contain;
    background-repeat: no-repeat;

}

.purple-card {
    background: url('../../assets/public/purple-card.png');
    color: white !important;
    ;
    background-size: contain;
    background-repeat: no-repeat;
}

.change-container {
    cursor: pointer;
    position: absolute;
    right: 0px;
    border-radius: 50px 0px 0px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #001055;
    height: 24px;
    width: 24px;
    z-index: 3;
}

.card-owner {
    padding: 8px 5px;
    background: rgba(184, 184, 184, 0.2);
    backdrop-filter: blur(9px);
    border-radius: 10px;
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
}

.card-owner-label {
    font-weight: bold;
}

.gold-card {
    background: url('../../assets/public/gold-card.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.card-digital-sign {
    border: 1px solid;
}

.sheba-number {
    margin-top: 30px;
    font-family: 'YekanLight';
    font-size: 12px;
    text-align: left;
}

.card-number {
    font-size: 12px;
    text-align: left;

}
</style>
