<template>
<div class="otc" v-if="crypto">
    <div class="trade-section">
        <SwitchComponent @choose="(choice)=>{dealType=choice}" :items="[{label:' فروش',value:'SELL'},{label:'خرید',value:'BUY'}]" />
        <DropDown class="base-coin-filter" @choose="coin=>baseCoin=coin" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]" />
        <DropDown class="crypto-filter" @choose="coin=>findCoin(coin)" :items="coinsOpts" />
        <LabelInput :value="0" @input="value=>amount=value" :label="'مقدار('+$coins[crypto.destCoinSymbol].code+')'" type="number" />
        <div class="depsoit">موجودی:
            &nbsp; {{(dealType=='BUY')?$coins[baseCoin].persianName:$coins[crypto.destCoinSymbol].code}}
            &nbsp; {{credit}}</div>

        <div class="currency-net">
            شبکه انتقال ارز
            <DropDown :items="networkOpts" />
        </div>
        <div class="coin-commission">کارمزد انتقال &nbsp;{{$coins[crypto.destCoinSymbol].code}}&nbsp; {{crypto.exchangeFee}}</div>
        <SwitchComponent v-if="dealType=='BUY'" @choose="(choice)=>{wallet=choice}" :items="[{label:'انتقال به کیف پول 700',value:'700'},{label:'انتقال به کیف پول دیگر',value:'OTHER'}]" />
        <LabelInput v-if="wallet=='OTHER' && dealType=='BUY'" label="آدرس کیف پول" />
        <p class="error">{{errorHandler}}</p>
        <div class="result">
            <div>مبلغ کل سفارش({{$coins[baseCoin].persianName}}) {{$toLocal(sum,this.baseCoin=='TOMAN'?0:2)}}</div>
            <div>کارمزد انتقال{{$coins[crypto.destCoinSymbol].code}}&nbsp;{{crypto.exchangeFee}}</div>

        </div>

        <button v-if="dealType=='SELL'" :disabled="disabled" :class="{disabled:disabled}" @click="checkModal()" class="sell-btn">ثبت سفارش فروش</button>
        <button v-else class="buy-btn" :disabled="disabled" :class="{disabled:disabled}" @click="checkModal()">ثبت سفارش خرید</button>
    </div>

    <div class="coin-details-order">
        <div class="crypto-details-container">
            <div class="crypto-icon">
                <img class="icon" :src="require('../../../../public/coins/'+crypto.destCoinSymbol+'.png')">
                <div>({{$coins[crypto.destCoinSymbol].code}})</div>
            </div>
            <div class="crypto-prices">
                <div class="crypto-price"><span class="crypto-price-label">قیمت فروش</span><span class="sell-price">{{$toLocal(crypto.sellPrice,this.baseCoin=='TOMAN'?0:2)}}</span><span class="sell-price crypto-price-tag">{{$coins[baseCoin].persianName}}</span></div>
                <div class="crypto-price"><span class="crypto-price-label">قیمت خرید</span><span class="buy-price">{{$toLocal(crypto.buyPrice,this.baseCoin=='TOMAN'?0:2)}}</span><span class="buy-price crypto-price-tag">{{$coins[baseCoin].persianName}}</span></div>
                <div class="crypto-minbuy"><span class="crypto-price-label">حداقل مبلغ سفارش</span><span>{{$toLocal(crypto.minAmountExchange,$decimal[crypto.destCoinSymbol])}}</span> <span class="crypto-price-tag">{{$coins[crypto.destCoinSymbol].persianName}}</span></div>
                <div class="crypto-minbuy"><span class="crypto-price-label">حداکثر مبلغ سفارش</span><span>{{$toLocal(crypto.maxAmountExchange,$decimal[crypto.destCoinSymbol])}}</span> <span class="crypto-price-tag">{{$coins[crypto.destCoinSymbol].persianName}}</span></div>
            </div>
        </div>

        <div class="orders-section-title">تاریخچه سفارشات</div>

        <div class="order" v-for="(order,index) in orders" :key="index">
            <div class="order-top">
                <div class="date">{{$G2J(order.createdAtDateTime)}}</div>
                <div class="status">{{statusLabel(order.orderStatus)}}</div>
            </div>

            <div class="order-bottom">
                <div>
                    <span class="order-crypto-title">{{coinTitle(order.marketType)}}</span>
                    <span :class="{'order-coin-amount':true, 'buy-order':order.orderType=='EXCHANGE_BUY','sell-order':order.orderType=='EXCHANGE_SELL'}">{{order.amount}}</span>
                </div>
                <div>
                    <span class="order-total-price">{{$toLocal(order.unitPrice,0)}}</span>
                    <span class="toman">تومان</span>
                </div>
            </div>
        </div>
        <TradeConfirm @confirm="sendData()" v-if="modalType=='BUY' || modalType=='SELL'" @modalToggle="value=>modalType=value" :order="order"/>
        <Success v-if="modalType=='success'" @modalToggle="value=>modalType=value" :order="order"/>

    </div>

</div>
</template>

<script>
import SwitchComponent from '../../tools/SwitchComponent.vue'
import DropDown from '../../tools/DropDown'
import TradeConfirm from '../../Modals/TradeConfirm.vue'
import Success from '../../Modals/Success.vue'
import {
    statusLabel,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'OTC',
    components: {
        SwitchComponent,
        DropDown,
        TradeConfirm,
        Success
    },
    data() {
        return {
            cryptoNetwork: "TRC20",
            baseCoin: 'TOMAN',
            depsoit: '',
            dealType: "SELL",
            walletAddress: '',
            cryptos: [],
            crypto: '',
            wallets: '',
            coinsDetails: [],
            net: '',
            amount: 0,
            modalType: '',
            data: {},
            toman: true,
            coinNet: '',
            wallet: '700',
            orders: [],
            loading: false,
            order:{},
            networkOpts:[]

        }
    },
    watch: {
        baseCoin() {
            this.getCoinsPrice()
        },
        unitPrice(){
            this.order.unitPrice = this.unitPrice

        }
    },
    computed: {

        coinsOpts() {
            return this.cryptos.map(coin => {

                return {
                    label: this.$coins[coin.destCoinSymbol].persianName,
                    value: coin.destCoinSymbol
                }
            })
        },
        statusLabel,
        tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return this.$toLocal(temp.credit, 0)
            }
            return 0
        },
        tetherBalance() {
            if (this.wallets['TETHER']) {
                const temp = this.wallets['TETHER']
                return this.$toLocal(temp.credit, this.$decimal[this.crypto.destCoinSymbol])
            }
            return 0
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY') ||
                (this.$S2N(this.dealType == 'BUY' ? this.sum : this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange))
        },
        errorHandler() {
            if ((this.$S2N(this.dealType == 'BUY' ? this.sum : this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.crypto.minAmountExchange + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.crypto.maxAmountExchange + ' باشد '
            else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY')
                return 'آدرس کیف پول را وارد نمایید'
            else
                return ''

        },
        credit() {
            return (this.dealType == 'BUY') ? (this.baseCoin == 'TOMAN' ? this.tomanBalance : this.tetherBalance) :
                this.$toLocal(this.crypto.credit, this.$decimal[this.crypto.destCoinSymbol])
        },
        sum() {
            return this.unitPrice * this.$S2N(this.amount)
        },
        unitPrice() {
            return this.$S2N((this.dealType == 'BUY') ? this.crypto.buyPrice : this.crypto.sellPrice)
        },
        sumExchangeFee() {
            return this.$S2N(this.crypto.exchangeFee) * this.$S2N(this.amount)

        }

    },
    methods: {
        coinTitle(marketType){
            return this.$coins[marketType.replace('_TOMAN','')].persianName || this.$coins[marketType.replace('_TETHER','')].persianName
        },
        findCoin(choiceCoin) {
            this.crypto = this.cryptos.find(coin => coin.destCoinSymbol == choiceCoin);
            this.setCrypto()
        },
        async getOrders() {
            const res = await this.$axios.get('/orders?orderType=EXCHANGE_BUY,EXCHANGE_SELL', {
                params: {

                }
            })
            this.orders = res.content;
        },
        async getCoinsPrice() {
            await this.$axios.get(`coins/exchange-price/${this.baseCoin=='TOMAN'?'usd':'usdt'}`, {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response).then(this.setCrypto)
        },
        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },
        async getCoinsDetails() {
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
        },

        setCrypto() {
                if (this.crypto)
                    this.crypto = this.cryptos.find(coin => coin.destCoinSymbol == this.crypto.destCoinSymbol)
                else {
                    this.crypto = this.cryptos[0]
                }
                    if (this.crypto && this.wallets && this.coinsDetails) {
                        this.crypto.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).minAmountExchange
                        this.crypto.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).maxAmountExchange
                        this.crypto.credit = this.wallets[this.crypto.destCoinSymbol].credit
                        this.crypto.nets = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).tokenTypeDetails
                        this.net = this.crypto.nets[0].tokenType
                        this.networkOpts = this.crypto.nets.map(net => {
                            return {
                                label: net.tokenType,
                                value: net.tokenType
                            }
                        })
                        this.crypto.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).exchangeFee
                    }

        },
        checkModal() {
            this.order.amount = this.amount
            // this.order.marketType = this.crypto.destCoinSymbol + '_' + (this.baseCoin)
            this.order.coin = this.crypto.destCoinSymbol
            this.order.tokenType = this.net
            // this.order.totalPrice = this.sum
            // this.order.unitPrice = this.unitPrice
            this.order.baseCoin = this.baseCoin
            this.order.tradeType = this.dealType
            this.order.orderType = "EXCHANGE"
            if (this.wallet == 'OTHER' && this.dealType == 'BUY')
                this.order.destinationWallet = this.walletAddress
            this.modalType = this.dealType
        },
        async sendData() {
            this.modalType=''
            this.temptData = JSON.parse(JSON.stringify(this.order))
            this.temptData.amount = this.$S2N(this.order.amount)
            this.temptData.orderType = this.order.orderType+'_'+this.order.tradeType
            this.temptData.marketType = this.crypto.destCoinSymbol + '_' + (this.baseCoin)

            this.temptData.totalPrice = this.$S2N(this.sum)
            this.temptData.unitPrice = this.$S2N(this.order.unitPrice)
            this.modalType = ''
            if (this.dealType == 'BUY') {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )

                this.modalType = 'success'

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.modalType = 'success'

                this.state.loading = false
            }

        },
        async update() {
            let tempCoin
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => tempCoin = response.find(coin => coin.destCoinSymbol == crypto.destCoinSymbol))

            if (tempCoin) {
                this.crypto.buyPrice = tempCoin.buyPrice
                this.crypto.sellPrice = tempCoin.sellPrice

            }

        }
    },
    async mounted() {
         console.log('dsfdsa')
        this.loading = true
        await this.getCoinsDetails()
        await this.getOrders()
        await this.getWallets()


        if (this.state.loop) {
            this.loop = new Loop(this.getCoinsPrice, 5000)
            this.loop.start()
        } else {
            this.getCoinsPrice()
        }
        this.loading = false
    },
    beforeDestroy() {
        this.loop.stop()

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.otc {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}

.otc>div {
    // flex-grow: 1;
}

.coin-details-order {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    background: var(--otc-section-bg);
    box-shadow: 0px 0px 64px rgba(28, 57, 187, 0.05);
    border-radius: 20px;
    row-gap: 24px;
}

.crypto-filter {
    z-index: 4;
}

.disabled{
    opacity: 0.7;
}

.base-coin-filter {
    z-index: 20 !important;

}
.error{
    color: #DD1B60;
    font-size: clamp(12px,1.3vw,14px);
}
.trade-section {
    background: var(--otc-section-bg);
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border-radius: 20px;
    min-width: 240px;
    flex-grow: 2;
    color: var(--otc-trade-text);
}

.result {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    font-size: clamp(12px, 1.2vw, 13px);
    font-family: 'YekanLight';
}

.currency-net {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    font-size: clamp(12px, 1.4vw, 14px);
    column-gap: 16px;

}

.depsoit {
    font-size: clamp(10px, 1.1vw, 12px);
}

.coin-commission {
    color: var(--otc-trade-comission);
    font-size: clamp(10px, 1.1vw, 12px);
}

.sell-btn {
    background: #FF2D78;
    box-shadow: -8px -8px 24px rgba(221, 27, 96, 0.4), 8px 8px 24px rgba(221, 27, 96, 0.4), inset -1px -1px 16px 1px rgba(171, 0, 61, 0.5), inset 1px 1px 16px 1px rgba(171, 0, 61, 0.5);
    border-radius: 50px;
    padding: 8px;
    color: white;
}

.buy-btn {
    background: #80B73B;
    box-shadow: -8px -8px 24px rgba(80, 141, 3, 0.05), 8px 8px 24px rgba(80, 141, 3, 0.05), inset -1px -1px 16px 1px rgba(80, 141, 3, 0.5), inset 1px 1px 16px 1px rgba(80, 141, 3, 0.5);
    border-radius: 50px;
    padding: 8px;
    color: white;

}

.order {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px 7px;
    font-size: clamp(13px, 1.3vw, 14px);
    height: 59px;

}

.order>div {
    display: flex;
    justify-content: space-between;
}

.date {
    color: var(--otc-orders-date);
    font-size: clamp(11px, 1.1vw, 12px);
}

.toman {
    color: var(--otc-orders-toman);
    font-size: clamp(12px, 1.1vw, 14px);

}

.order-crypto-title {
    color: var(--otc-orders-coin);
    font-size: clamp(13px, 1.4vw, 14px);
}

.orders-section {}

.order-total-price {
    color: var(--otc-orders-price);
    font-size: clamp(12px, 1.1vw, 14px);

}

.sell-order {
    color: #DD1B60;
}

.buy-order {
    color: #80B73B;
}

.order-coin-amount {
    font-size: clamp(12px, 1.3vw, 14px);
}

.order-bottom>div {
    display: flex;
    column-gap: 4px;
}

.status {
    color: var(--otc-orders-status);
    font-size: clamp(11px, 1.1vw, 12px);

}

.orders-section {
    flex: 1;
    flex-basis: 240px;
}

.crypto-details-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 15px;
    padding: 12px;

}

.icon {
    width: 40px;
}

.crypto-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    row-gap: 8px;
    color: var(--otc-details-title);
    font-size: clamp(12px, 1.3vw, 14px);
    font-family: 'YekanLight';
    padding-left: 15px;
}

.crypto-price {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: clamp(13px, 1.3vw, 14px);
    color: var(--otc-details-price-label);
    text-align: center;

}

.sell-price {
    color: var(--otc-details-sellprice);
}

.buy-price {
    color: var(--otc-details-buyprice);

}

.crypto-prices {
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 11px;
}

.orders-section-title {
    font-size: clamp(16px, 1.7vw, 18px);
    color: var(--otc-orders-section-title);
}

.crypto-price-tag {
    text-align: left;
    font-family: 'YekanLight';
}

.crypto-price-label {
    text-align: right;
    font-family: 'YekanLight';
}

.crypto-minbuy {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: var(--otc-details-minbuy);
    font-size: clamp(12px, 1.3vw, 13px);
    text-align: center;
}
</style>
