<template>
<div class="switch">
    <div :class="{'active':choice==item.value,'inactive':choice!=item.value}" v-for="(item,index) in items " 
        :key="index" @click="choice=item.value;$emit('choose',choice)" >
        {{item.label}}
    </div>
    
</div>
  
</template>

<script>
export default {
  name: 'ToggleComp',
  props:{
      items:{type:Array},
    
  },
  data(){
      return {
          choice:this.items[0].value
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    width: 100%;
    display: flex;
    font-size: clamp(13px,1vw,14px);
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 8px;
}
.switch > div{
      white-space: nowrap;
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 20px;
      border-radius: 50px;
    
}

.inactive{
    background: var(--toggle-comp-inactive-bg);
    color:var(--toggle-comp-inactive-text);
    box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1), 
    inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
}
.active{
    background:#FFCA2E;
    /* background: white; */
    box-shadow: -8px -8px 24px rgba(255, 191, 0, 0.2), 8px 8px 24px rgba(255, 191, 0, 0.2), inset -1px -1px 16px 1px rgba(192, 144, 0, 0.5), inset 1px 1px 16px 1px rgba(192, 144, 0, 0.5);
    color:#3D3D3D;
    
}

</style>