<template>
   <div class="section">
     <div class="header">
            <div class="section-title">اطلاعات شخصی</div>
            <inline-svg @click="$emit('editToggle')" class="edit-icon" :src="require('../../../assets/icons/edit.svg')"/>
     </div>
            <form class="input-fields">
                <LabelInput  :disabled="readOnly"  @input="value=>data.firstName=$S2Fa(value)" :value="data.firstName" label='نام'/>
                <LabelInput    :disabled="readOnly" @input="value=>data.lastName=$S2Fa(value)" :value="data.lastName" label='نام خانوادگی'/>
                <LabelInput type="nochar"  :maxLength="10" @input="value=>data.nationalCode=value"   :value='data.nationalCode'  :disabled="readOnly" label='کدملی'/>
                <LabelInput type="nochar"   :maxLength="11"  @input="value=>data.mobileNumber=value" :value="data.mobileNumber"  label='تلفن همراه'/>
                <LabelInput type="nochar"  :maxLength="11" @input="value=>data.telephone=value" :value="data.telephone" label='تلفن ثابت'/>
                <Calendar :disabled="readOnly" :isBirth="true" @input="value=>data.birthDay=value" :value="data.birthDay"  label='تاریخ تولد'/>
                <textarea v-model="data.address"  rows="3" placeholder="آدرس محل زندگی"/>  
            </form>
        </div> 
    
</template>

<script>
import Calendar from '../../tools/Calendar'
export default {
  name: 'PersonalFields',
  components:{
      Calendar
  },
   props: {
     data:Object,
     readOnly:Boolean
     },
  data(){
    return {
    
    }
  }
 
}
</script>
<style scoped>

textarea{
    width: 100%;
      background: var(--label-input-bg);
     color: var(--label-input-text);
     border:0.3px solid var(--label-input-border) ;
     border-radius: 20px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:  var(--label-input-text);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  var(--label-input-text);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:  var(--label-input-text);
}

.disabled{
  background: var(--label-input-disabled);

}
.section{
  /* display: none; */

}
.section-title{
    
}
textarea:focus {
  outline: none !important;
 
}

.input-fields > div{
  flex-basis: 300px!important;
 
}
.input-fields {
  display: flex;
  
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-icon{
  stroke: var(--authentication-creditcard-icon);
  cursor: pointer;
}

</style>