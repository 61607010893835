<template>
<div class="comments">
    <h1 class="header">آنچه کاربران می گویند</h1>
    

        <vueper-slides
            :breakpoints="breakpoints"
            class="no-shadow comments-carousel"
            :visible-slides="3"
            :gap="2"
            :arrows="true"
            :bullets="false"
            :arrowsOutside="false"     
            :rtl="true"
            :autoplay="true"
            :duration="2000"
            :slide-ratio="1 / 4"
        >
        <vueper-slide  v-for="(comment,index) in comments" :key="index">
        <template #content>
        <div class="vueperslide__content-wrapper" >
           
            <div class="comment">

                <img src="../../../assets/icons/user.svg"/>
                <div class="title">{{comment.author}}</div>
                <div class="description">{{comment.description}}</div>
                <div class="stars">
                    <inline-svg v-for="i in 5" :key="i" :class="{star:true,'active-star':comment.rate>=i}" :src="require('../../../assets/icons/star.svg')"/>
                </div>


            </div>    

           

        </div>
        </template>
        </vueper-slide>
        <template #arrow-left>
            <div class="arrow">
                <img src="../../../assets/public/carousel-arrow.svg"/>
            </div>
        </template> 
        <template #arrow-right>
            <div class="arrow reverse-arrow">
                <img src="../../../assets/public/carousel-arrow.svg"/>
            </div>
        </template>
            </vueper-slides>
    

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import variables from '../../../library/variables.json'
export default {
  name: 'Comments',
  props: {
   
  },
  data(){
    return{
          cryptos:variables.cryptos,
          breakpoints: {
                1200: {
                  visibleSlides: 2
                },
                 900: {
                  visibleSlides: 1,                
                },
               
              },

          comments:[
              {author:'شایان کریمی',rate:4 , description:'ابزارهای مدرن برای انجام معاملات از جمله حد سود و ضرر، پنل گزارش سود و زیان، امکان تنظیم هشدار قیمت و همچنین نمودارهای قیمتی و ابزارهای تحلیل بازار در اختیار شماست.'},
              {author:'علی احمدی',rate:5  , description:'با بیش از ۶۰۰ هزار کاربر فعال، معامله خود را در سریع‌ترین زمان ممکن انجام دهید.'},
              {author:  'غلی رجب زاده',rate:4  , description:'دارایی‌های شما در کیف‌پول اختصاصی به صورت سرد نگه‌داری می‌شود و دربرابر حمله‌های مختلف امنیت دارد.'},
              {author:'مرجان خلیل پور',rate:1  , description:'معامله‌ی بهترین کوین‌های بازار در سه پایه بازار متفاوت و متنوع‌تر شدن سبد معاملات، یک ویژگی مهم برای کاربران حرفه‌ای است.'},
              {author:'رضا سرپوشان',rate:5  , description:'در صرافی 700 در کمتر از پنج دقیقه ثبت نام و معامله در پرسودترین بازارها را آغاز کنید.'},
            //   {author:'شایان کریمی',rate:4  , description:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است '},
            //   {author:'شایان کریمی',rate:3  , description:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است '},
            //   {author:'شایان کریمی',rate:5  , description:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است '},
                      ]     
    }

  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vueperslide__content-wrapper{
    display: flex;
    align-items: center;
    //  max-height: 208px;
}
.stars{
    display: flex;
    column-gap: 7px;
    direction: ltr;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
}
.star{
    stroke:#CCCCCC;
}

.active-star{
    stroke:#FFBF00;

}
.comment{
   border-radius: 20px;
   padding: 25px 16px 20px 16px;
   background: var(--publicpage-comment-bg);
   flex-direction: column;
   align-items: center;
//    max-width: 284px;
//    max-height: 208px;
   display: flex;
}

.comments{
width: 100%;
  border-radius: 10px;
  background:var(--publicpage-comments-bg);
  display: flex;
//   height:306px;
  padding: 24px 29px;
  align-items: center;
  flex-direction: column;
}
.vueperslide__title{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.comments-carousel{
    width: 100%;
//    max-height: 208px;

}


.reverse-arrow{
    transform: rotatez(180deg);
}

.arrow{

    margin-bottom: 80px;
}


.description{
    color: var(--publicpage-comment-description);
    font-size: 12px;
    text-align: right;
    font-weight: 300;
    margin-top:8px;

}

.title{
    font-weight: bold;
    font-size: 14px;
    color:var(--publicpage-comment-title);
    margin-top:16px;
}
.header{
    font-size: clamp(20px,2vw,24px);
    color:var(--publicpage-comments-header);
    font-weight: bold;
}
</style>