<template>
  <div class="footer">
      <div class="sections">
      <div class="section about">
          <div class="head">
              <!-- <div class="site-icon">700</div> -->
              <div> صرافی 700</div>
          </div>
          <div >
              این صرافی با ارائه خدمات متنوع و پشتیبانی 24 ساعته، پلتفرمی امن برای مبادلات ارزهای دیجیتال است.
          </div>

      </div>
      <div class="section ">
          <div class="head">دسترسی سریع</div>
          <a class="link" :href="'/'+link.path" v-for="(link,index) in guideLinks" :key='index'>{{link.label}}</a>
      </div>
      <div class="section address-info">
          <div class="head">تماس با ما</div>
          <div >{{info.address}}</div>
          <div >{{info.number}}</div>
          <div >{{info.email}}</div>
      </div>
      <div class="section bg-container">
          <img src="../../assets/public/footer-coins.png"/>
      </div>
      </div>



      
      <div class="foot">
          <div class="copyright-text">تمام حقوق برای شرکت ویستا محفوظ میباشد.</div>
          <div class="commites">
              <div class="medias-header">انجمن ها</div>
              <inline-svg class="media-icon" v-for="(icon,index) in icons" :key="index" :src="require('../../assets/icons/'+icon+'.svg')"/>
          </div>
          
      </div>

   

  </div>
    
</template>

<script>
export default {
  name: 'Footer',
    methods:{
          async getInfo() {
            const res = await this.$axios.get('/exchange-info?language=PERSIAN')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
             this.info.address = res.baseDTO.address
             this.info.number = res.baseDTO.number
             this.info.email = res.baseDTO.email
        },

  },
  
  data(){
    return{
        info:{
            address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
        },

       
         guideLinks:[
             {label:'درباره ما',path:'AboutUs'},
            {label:'قوانین و مقررات ',path:'Terms'},
            {label:'سولات متداول',path:'FAQ'},
            {label:'پشتیبانی',path:'ContactUs'},
            {label:' بلاگ',path:'Blog'},


        ],
         icons:[
              'instagram',
              'whatsapp',
              'twitter',
              'telegram',
              'gmail',
              'linkedin',
              'facebook',
              'google',
          ]
      
    }
  },
    mounted(){
      this.getInfo()
  }

}
</script>
<style scoped>
.footer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 12px;
    min-width: 256px;
    background: var(--footer-bg);
    color:var(--footer-text);
    width: 100%;
    padding: 39px;

}

.link{
    color:var(--footer-text);

}
.bg-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg-container > img{
    width: 100%;
}

.commites{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap:20px
}
.media-icon{
    height: 12px;
    width: 12px;
    stroke:var(--footer-icon);
}
.medias-header{
     color:var(--footer-medias-header);
     font-size:clamp(16px,1.6vw,18px);
     white-space: nowrap;
     font-weight: bold;
}
.copyright-text{
    font-size: clamp(11px,1vw,12px);
}
.foot{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.logo{
    display: flex;
    justify-content: center;
   flex-grow: 1;
}

.about{
    max-width:303px;
}
.address-info{
    max-width:215px;
}
.logo > img{
    width: 100%;
    max-width: 400px;
    height: auto;
}
.sections{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
    font-size: clamp(12px,1.4vw,14px);
    border-bottom: 0.2px solid var(--footer-line);

}
.head{
    display: flex;
    font-weight: bold;
    
    font-size: clamp(16px,1.6vw,18px);
    color: var(--footer-section-title);
}
.contacts{
    display: flex;
    row-gap: 30px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}
.section{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-size: clamp(13px,1.3vw,14px);

}

.apps-icon{
    display: flex;
    justify-content: space-between;
}
</style>