<template>
<div class="faq">
        <div class="head-part">
            <div class="questions">
                 <div class="page-title">سوالات متداول</div>
                <div v-for="(question,index) in questions.slice(0,4)" :key="index" class="question" @click="openedIndex=(openedIndex==index)?null:index" >
                    <div class="question-row" >
                        <span>{{question.question}}</span>
                        <inline-svg :class="{'drop-icon':true,'drop-open':openedIndex==index}" :src="require('../../assets/icons/arrowdown.svg')"/>
                    </div>
                    <div v-if="openedIndex==index" class="answer">
                        <div>{{question.answer}}</div>
                    </div>
                </div>
            </div>
            <div class="img-container">
                <img src="../../assets/public/faq-img.png" class="img"/>
            </div>

        </div>
    <div class="questions">
         <div v-for="(question,index) in questions.slice(4,10)" :key="index" class="question" @click="openedIndex=(openedIndex==index+4)?null:index+4" >
            <div class="question-row" >
                <span>{{question.question}}</span>
                <inline-svg :class="{'drop-icon':true,'drop-open':openedIndex==index+4}" :src="require('../../assets/icons/arrowdown.svg')"/>
            </div>
            <div v-if="openedIndex==index+4" class="answer">
                <div>{{question.answer}}</div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
// import axios from 'axios'
export default {
  name: 'FAQ',
  data(){
    return{
        openedIndex:null,
        questions:[
              ],
        
     
      
    }
  },
   mounted(){
      this.getFaq()
      console.log(this.questions)

  },
  methods:{
     async getFaq(){
            this.questions=await this.$axios.get('faq?language=PERSIAN').then(response => this.questions=response)
            
      }
  
  }
}
</script>
<style scoped>
.drop-icon{
    stroke: var(--faq-question-dropicon);
    cursor: pointer;
}
.question{
background: var(--faq-question-bg);
cursor: pointer;
padding: 0px 16px;
border-radius: 20px;
width: 100%;
}

.head-part{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}
.head-part > .questions{
    max-width: 600px;
}

.questions{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 16px;
}

.img-container{
    flex-grow: 1;
    display: flex;
    min-width: 200px;

    justify-content: flex-end;
   
}

.page-title{
    font-weight: bold;
    font-size: clamp(22px,2.5vw,32px);
    color:var(--publicpage-title)
}
.img-container > img{
   width: 100%;
   max-width: 445px;

}
.faq{
     margin-top: 90px;
    padding-bottom: 100px;
    width: 85vw;
}
.answer{
    display: flex;
    font-size: clamp(11px,1.2vw,14px);
    font-weight: lighter;
   color: var(--faq-question-answer);
   padding-bottom: 8px;
}

.drop-open{
    transform: rotate(180deg);
}

.question-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    font-size: clamp(12px,1.3vw,14px);
    color: var(--faq-question-title);
}
</style>