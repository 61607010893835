<template>
<div class="table-section">
    <div class="filters">
        <div class="market-filter">
            <SwitchComponent @choose="(choice)=>{tableType=choice}" :items="[{label:'سفارشات فعال',value:'ACTIVE'},
                {label:'تاریخچه سفارشات',value:'HISTORY'},{label:'توقف بازار ',value:'STOP_MARKET'},{label:'توقف حد ',value:'STOP_LIMIT'}]" />
        </div>
        <div class="trans-filter">
            <SwitchComponent @choose="(choice)=>{tradeType=choice}" :items="[{label:'فروش',value:'SELL'},{label:'خرید',value:'BUY'}]" />
        </div>
    </div>
    <table v-if="tableType=='ACTIVE'" class="">
        <tr class="table-header">
            <td class="resp-1"> زمان </td>
            <td>نوع ارز </td>
            <td class="resp-1">نوع سفارش</td>
            <td> مقدار <span class="tag">{{$coins[coin].code}}</span> </td>
            <td >قیمت واحد</td>
            <td class="resp-2">قیمت کل</td>
            <td class="resp-2">انجام شده</td>
            <td > عملیات</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filteredOrders" :key="index">
                <td class="resp-1"> {{$G2J(order.createdAtDateTime)}}</td>
                <td>
                    <div class="coin-title">
                        <img class="crypto-icon resp-1" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                        <div>{{$coins[coinName(order.marketType)].code}}</div>
                    </div>
                </td>
                <td class="resp-1" :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td> {{order.amount}}</td>
                <td> {{$toLocal(order.unitPrice,$decimal[baseCoin(order.marketType)])}}</td>
                <td class="resp-2">{{$toLocal(order.wholePrice,$decimal[baseCoin(order.marketType)])}}</td>

                <td class="resp-2"> {{order.executedPercent}}%</td>
                <td >
                    <div class="action">لغو
                        <inline-svg class="delete-icon" :src='require("../../../assets/icons/delete.svg")' />
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
    <table v-if="tableType=='HISTORY'" class="">
        <tr class="table-header">
            <td>نوع ارز</td>
            <td class="resp-1"> تاریخ و زمان </td>
            <td> نوع سفارش</td>
            <td> قیمت واحد</td>
            <td> مقدار &nbsp;<span class="tag">{{$coins[coin].code}}</span> </td>
            <td class="resp-2"> قیمت کل</td>
            <td class="resp-1"> وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filteredOrders" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="crypto-icon resp-1" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                        <div>{{$coins[coinName(order.marketType)].code}}</div>
                    </div>
                </td>
                <td class="resp-1"> {{$G2J(order.createdAtDateTime)}}</td>
                <td :class="{'depsoit-buy':order.orderType.includes('BUY'),'withdraw-sell':order.orderType.includes('SELL')}">
                    {{orderType(order.orderType)}}</td>
                <td> {{$toLocal(order.unitPrice,$decimal[baseCoin(order.marketType)])}}&nbsp;{{$coins[baseCoin(order.marketType)].persianName}}</td>
                <td> {{order.amount}}</td>
                <td class="resp-2">{{$toLocal(order.wholePrice,$decimal[baseCoin(order.marketType)])}}&nbsp;{{$coins[baseCoin(order.marketType)].persianName}}</td>
                <td class="resp-2 ">
                    <div class="status">{{statusLabel(order.orderStatus)}}<img :src="require('../../../assets/icons/success.svg')" /></div>
                </td>

            </tr>
        </tbody>
    </table>

    <table v-if="tableType=='STOP_MARKET'" class="">
        <tr class="table-header">
            <td>نوع ارز</td>
            <td class="resp-1"> زمان </td>
            <td> نوع سفارش</td>
            <td class="resp-1"> حد توقف</td>
            <td> قیمت خرید</td>
            <td> مقدار(تومان)</td>
            <td class="resp-2"> وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filteredOrders" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="crypto-icon resp-1" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                        <div>{{$coins[coinName(order.marketType)].code}}</div>
                    </div>
                </td>
                <td class="resp-1"> {{$G2J(order.createdAtDateTime)}}</td>

                <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td class="resp-1"> {{$toLocal(order.stopPrice,$decimal[baseCoin(order.marketType)])}}</td>

                <td> {{order.unitPrice.toLocaleString()}}</td>
                <td> {{order.amount}}</td>
                <td class="resp-2 ">
                    <div class="status">لغو
                        <inline-svg class="delete-icon" :src='require("../../../assets/icons/delete.svg")' />
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
    <table v-if="tableType=='STOP_LIMIT'" class="">
        <tr class="table-header">
            <td>نوع ارز</td>
            <td class="resp-1"> زمان </td>
            <td> نوع سفارش</td>
            <td> قیمت واحد (تومان)</td>
            <td> مقدارارز</td>
            <td class="resp-1"> حد توقف(تومان)</td>
            <td class="resp-2"> تاریخ انقضاء</td>
            <td class="resp-2"> وضعیت</td>

        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filteredOrders" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="crypto-icon resp-1" :src="require('../../../../public/coins/'+coinName(order.marketType)+'.png')" />
                        <div>{{$coins[coinName(order.marketType)].code}}</div>
                    </div>
                </td>
                <td class="resp-1"> {{$G2J(order.createdAtDateTime)}}</td>

                <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td> {{$toLocal(order.unitPrice,$decimal[baseCoin(order.marketType)])}}</td>
                <td> {{order.amount}}</td>
                <td class="resp-1"> {{$toLocal(order.stopPrice,$decimal[baseCoin(order.marketType)])}}</td>
                <td class="resp-2"> {{$G2J(order.expiresAt)|| '-'}}</td>

                <td class="resp-2 ">
                    <div class="status">لغو
                        <inline-svg class="delete-icon" :src='require("../../../assets/icons/delete.svg")' />
                    </div>
                </td>

            </tr>
        </tbody>
    </table>

</div>
</template>

<script>
import SwitchComponent from '../../tools/SwitchComponent.vue'
import {
    statusLabel,
    statusColor

} from "@/library/reuseableFunction";

export default {
    name: 'DashboardTable',
    components: {
        SwitchComponent
    },
    computed: {
        statusLabel,
        statusColor,
        filteredOrders() {
            if(this.orders)
                return this.orders.filter(order =>
                    ((this.tableType == 'ACTIVE' && order.orderStatus == 'IS_OPEN') ||
                        (this.tableType == 'HISTORY' && order.orderStatus != 'IS_OPEN') ||
                        (this.tableType == 'STOP_LIMIT' && order.orderType.includes('STOP_LIMIT')) ||
                        (this.tableType == 'STOP_MARKET' && order.orderType.includes('STOP_MARKET'))) &&
                    order.orderType.includes(this.tradeType)

                )
            return []    

        }

    },
    props: ['coin'],
    data() {
        return {
            orders: [],
            tableType: 'ACTIVE',
            dict: {
                'SELL': 'فروش',
                'BUY': 'خرید',
                'STOP_MARKET': 'توقف بازار',
                'STOP_LIMIT': 'توقف حد',
                'LIMITED': 'حد',
                'MARKET': 'بازار',
                'EXCHANGE': 'صرافی'
            },
            tradeType: 'SELL',
            statusDict: {
                'CHECKING': 'در حال بررسی',
                'SENDING': 'در حال ارسال',
                'SUCCESS': 'موفق',
                'FAIL': 'ناموفق',
            }

        }
    },

    methods: {
        orderType(orderType) {
            let typeTemp = orderType.includes('_SELL') ? 'SELL' : 'BUY'
            let temp = orderType.replace('_' + typeTemp, '')
            return this.dict[temp] + '-' + this.dict[typeTemp]
        },
        coinName(market) {
            return market.includes('_TETHER') ? market.replace('_TETHER', '') : market.replace('_TOMAN', '')
        },
        baseCoin(market) {
            return market.includes('TOMAN') ? 'TOMAN' : 'TETHER'
        },
        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },
        cancelOrderModal(e) {
            // this.Loop.stop()
            this.modalType = 'CANCEL'
            this.removeid = e.id
        },
        tradeTypeDict(type) {
            return type.includes('EXCHANGE') ? 'صرافی' : type.includes('MARKET') ? 'بازار' : 'حد'
        },
        async check() {
            // if (this.page<=this.lastPage){ //for scroll loading
            // this.page ++ //for scroll loading
            let coinQuery = '?'
            if (this.coin != 'TETHER')
                coinQuery += '&market=' + this.coin + '_' + 'TETHER'
            coinQuery += '&market=' + this.coin + '_' + 'TOMAN'

            this.state.loading = true
            const res = await this.$axios.get(`/orders${coinQuery}`, {
                params: {
                    size: 10,

                    // type: 'LIMITED_SELL',
                    // orderStatus: "IS_OPEN"
                }
            })
            // this.lastPage = res.totalPages
            // this.dataSet = [...this.dataSet, ...res.content] 
            this.orders = res.content

        },

    },
    mounted() {
        this.check()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.coin-title {
    display: flex;
    column-gap: 4px;
}

.table-section {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    background: var(--dashboard-section-bg);
    border-radius: 20px;
    flex-grow: 1;
}

.negative-arrow {
    transform: rotate(180deg)
}

.crypto-icon {
    height: 16px;
    width: 16px;
}

.status {
    display: flex;
    align-items: center;
}

.delete-icon {
    stroke: #DD1B60;
}

table {
    margin-top: 18px;
    flex-grow: 1;
    font-size: clamp(11px, 1.1vw, 12px);
    color: var(--dashboard-table-text);
    font-family: 'YekanLight';
}

.action {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.order-type {
    color: var(--dashboard-table-ordertype)
}

// tr{
//     height:32px;
// }

td {
    // padding: 6px;
}

.withdraw-sell {
    color: var(--table-red);

}

.depsoit-buy {
    color: var(--table-green);
}

.profity {
    color: var(--table-green);
    stroke: var(--table-green);
}

.harmy {
    color: var(--table-red);
    stroke: var(--table-red);

}

.status {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    align-items: center;
    justify-content: space-between;
}

.market-filter {
    max-width: 600px;
    flex-grow: 1;
}

.trans-filter {
    max-width: 121px;
    flex-grow: 1;

}

@media(max-width:630px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}
</style>
