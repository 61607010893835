<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        درﺧﻮاﺳﺖ ﺷﻤﺎ ﺑﺎ ﻣﻮﻓﻘﯿﺖ اﻧﺠﺎم ﺷﺪ
    </template>
    <template #body>
        <div>
            <div class='detail'>
                <span>نوع درخواست</span>
                <span :class="{buy:order.tradeType=='BUY' ,sell:order.tradeType=='SELL'}">{{dict[order.tradeType]+' '+dict[order.orderType]}}</span>
            </div>
            <div class='detail'>
                <span> مقدار</span>
                <span dir="ltr">{{order.amount}}&nbsp;{{$coins[order.coin].code}}</span>
            </div>
            <div class='detail'>
                <span>قیمت واحد</span>
                <span>{{ $toLocal(order.unitPrice,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].persianName}}</span>
            </div>
            <div class='detail'>
                <span> مجموع</span>
                <span>{{$toLocal(sum,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].persianName}}</span>
            </div>

        </div>
        <button class="modal-submit-btn">تایید </button>
    </template>

</ModalCard>
</template>

<script>
import ModalCard from './ModalCard'
export default {
    name: 'success',
    components: {
        ModalCard,
    },
    computed:{
        sum(){
          return this.order.unitPrice * this.order.amount
      }
    },
    props: {
        order: Object
    },
    data() {
        return {
            dict: {
                "MARKET": 'بازار',
                "LIMIT": 'حد',
                "EXCHANGE": 'صرافی',
                "BUY": 'خرید',
                "SELL": 'فروش'
            }

        }
    }
}
</script>

<style scoped>
.text {
    font-size: 12px;
    color: var(--modal-text)
}

.details {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.buy{
    color: var( --modal-orderType-buy);
}
.sell{
    color: #DD1B60;
}
.detail,
.detail * {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    font-size: clamp(12px, 1.4vw, 14px);
    font-family: 'YekanLight';
}
</style>
