<template>
  <AuthCard @close="$emit('authModalToggle','')">
     <template #title>
       تعیین رمز عبور 
    </template>
     <template #body>
        <p class="text" v-if="pathMatch === 'Email'">کد ۶ رقمی که به ایمیل
            {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
        <p class="text" v-else-if="pathMatch === 'SMS'"> کد ۶ رقمی که به شماره
            {{state.userInfo.mobileNumber}} ارسال شده است را وارد
            نمایید.</p>
        <p class="text" v-else-if="pathMatch === 'GoogleAuth'">کد ۶ رقمی سرویس
            Google Authenticator را وارد نمایید.</p>
         <VerifyCodeInput @change="e=>otp.code=e"  :num="6" />

         <div class="text">رمز عبور جدید خود را وارد نمایید</div>
         <LabelInput @input="value=>otp.password=value" type='password' label=" رمز عبور  " />
         <LabelInput type='password' label=" تکرار رمز عبور  " @input="value=>{repass=value;}" />
        <span v-if="pathMatch !== 'GoogleAuth'" class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}}</span>

           <button class="auth-submit-btn" v-if="countDown===0" @click.prevent="send()" >ارسال مجدد   </button>
         <button class="auth-submit-btn" v-if="countDown>0"  @click="submit()">ثبت تغییرات </button>
     </template> 
   

  </AuthCard>
    
</template>

<script>
import AuthCard from './AuthCard'
export default {
  name: 'ForgetPass',
  components:{
      AuthCard
  },
  data(){
    return{
          pathMatch:'',
            countDown: 120,
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username') || '',
                password: ''
            },
            repass:''
      
    }
  },
  computed: {
        disable() {
            return !this.otp.password || this.passError || this.otp.code.length !== 6 || (this.pathMatch !== 'GoogleAuth' && this.countDown === 0) || this.state.loading
        },
          passError() {
            if (!this.otp.password) {
               return 'رمز اجباری';
            } else {
                if (this.otp.password.length < 8) {
                    return'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.otp.password)) {
                   return 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if(this.repass!==this.otp.password){
                     return'رمز عبور و تکرار آن مطابقت ندارند'
                }
                else {
                    return ''
                }
            }
          
        },
    },
    methods: {
        showPass() {
            if (this.type === "password") {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
      
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        validPass(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        },
        async submit() {
          console.log('check1')
            if ( !this.disable) {
                this.state.loading = true
                let [res, ] = await this.$http.post('/users/reset-password', this.otp)
                

                if (res) {
                  console.log('check3')

                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    this.$emit('authModalToggle','Login')

                }

            }
            else if(this.passError){
               this.$error(this.passError, '', 'error')

            }
        },
        async send() {
            this.state.loading = true
            // this.reset()
            await this.$axios.post('/users/forget-password?email=' + this.otp.email)
            this.$error('کد جدید ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)

            if (this.countDown === 0) {
                if (this.state.userInfo.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR')
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        this.pathMatch=this.state.userInfo.towStepAuthenticationType
        if (!this.$cookies.isKey('username')) {
            this.$router.push('/login')
        }
        if (this.pathMatch !== 'GoogleAuth') {
            this.countDownTimer()
        }
    }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    color:var(--modal-text)

}
.disabled{
  opacity: 0.7;
}
</style>

