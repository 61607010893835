<template>
<AuthCard @close="$emit('authModalToggle','')">
    <template #title>
        شناسایی دو عاملی
    </template>
    <template #body>
           <p class="text" v-if="verifyType === 'Email'">کد ۶ رقمی که به ایمیل {{otp.email}} ارسال شده است
                را وارد نمایید.</p>
            <p class="text" v-else-if="verifyType === 'GoogleAuth'">کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.</p>
            <p class="text" v-else-if="verifyType === 'SMS'">
                کد ۶ رقمی که به شماره
                {{otp.phoneNumber}}
                ارسال شده است را وارد نمایید.</p>
        <VerifyCodeInput @change="e=>onChange(e)"   @complete="submit()" :num="6"/>
        <span v-if="verifyType !== 'GoogleAuth'" class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}}</span>

        <button class="auth-submit-btn" :class="{disabled:otp.code.length<6}" v-if="countDown >0" :disabled="otp.code.length<6" @click="submit()">تایید</button>
        <button class="auth-submit-btn" v-if="countDown==0" @click="send()">ارسال مجدد کد</button>

    </template>

</AuthCard>
</template>

<script>
import AuthCard from './AuthCard'
// import VerifyCodeInput from '../tools/VerifyCodeInput'
export default {
    name: 'OtpEmail',
    components: {
        AuthCard,
        // VerifyCodeInput
    },
    data() {
        return {
            verifyType: this.state.userInfo.towStepAuthenticationType,
            verifyTypes: {
                'Email': 'ایمیل',
                'SMS': 'شماره موبایل',
                'GoogleAuth': 'شناسایی گوگل'
            },
            countDown: 120,
            error: '',
            code: '',
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username'),
                phoneNumber: this.state.userInfo.mobilenumber
            }
        }
    },
      methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.state.loading = true
            await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
            this.$error('کد جدید به ' + this.verifyTypes[this.verifyType] + ' شما ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)
            if (this.countDown === 0) {
                this.countDown = 120
                this.countDownTimer()
            }

        },

        onChange(e) {
            this.otp.code = e
        },
        async submit(){
            if (this.countDown !== 0) {
                this.state.loading = true
                const res = await this.$axios.post('/users/check-otp-code', this.otp)
                if (res.token) {
                    await this.$setToken(res.token)
                    this.$emit('authModalToggle','')
                    await this.$router.push('/Panel/Profile')
                }
            }

        },

    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        if (!this.otp.email) {
            this.$emit('authModalToggle','Login')
        }
        if (   this.verifyType !== 'GoogleAuth' ) {
            this.countDownTimer()
        }
    }
    

}
</script>

<style scoped>
.text {
    font-size: 12px;
    color: var(--modal-text)
}
.disabled{
  opacity: 0.7;
}
</style>
